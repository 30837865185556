import { Button, Form, Spinner } from "reactstrap";
import FormInput from "../form-input/form-input.component";
import { useState } from "react";
import { toast } from "react-toastify";
import { setCurrentAdmin, updateAdminProfile } from "../../store/admin/admin.action";
import { showServerErrorMessage } from "../../utils/show-server-error-message";
import { useDispatch } from "react-redux";
import { updateEmployeeProfile } from "../../store/employees/employee.action";

const Profile = ({ toggle,  currentAdmin}) => {
    const { user } = currentAdmin;
    console.log("USER", user);
  const [values, setValues] = useState({
    name: user.name,
    email: user.email,
    password: "",
    confirmPassword: "",
    profileImage: "",
    role: user.type === 'Admin' ? user.role : user.employeeType
  });

  const dispatch = useDispatch();

  const profileImage =
      user.profileImage ? user.profileImage
      : null;

  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(profileImage);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    values.profileImage = photoUrl ? photoUrl : null;
    try {
        const res =
        user.type === "Admin"
          ? await updateAdminProfile(
              { ...values, id: user.id },
              user.id
            )
          : await updateEmployeeProfile(
              { ...values, id: user.id },
              user.id
            );
      if (res.status && (res.status === 200 || res.status === 201)) {
        const { data } = res.data;
        currentAdmin.user = data;
        dispatch(setCurrentAdmin(currentAdmin))   
        localStorage.setItem('user-data', JSON.stringify(currentAdmin));
        toast.success(`Prfoile details updated successfully!`);
      } else {
        toast.error("Profile update failed. please try again later");
      }
      setIsSaving(false);

      toggle();
    } catch (err) {
      toast.error("Prfoile update encountered an error");

      console.log("Form catched Error", err);

      setIsSaving(false);
    }
  };
  return (
    <>
      <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
        <div className="form-row row mb-3">
          <div className="col-12">
            <div className="row">
              <div className="col-8">
                <div className="form-group mb-3">
                  <label className="col-form-label">Name</label>
                  <FormInput
                    type="text"
                    id="name"
                    onChange={onChange}
                    name="name"
                    placeholder="ABCDEF"
                    value={values["name"]}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="col-form-label">Email Address</label>
                  <FormInput
                    type="email"
                    id="email"
                    onChange={onChange}
                    name="email"
                    placeholder="Test@gmail.com"
                    value={values["email"]}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="col-form-label">Role</label>
                  <FormInput
                    type="text"
                    id="role"
                    name="role"
                    value={values["role"]}
                    required
                    disabled
                  />
                </div>
                <div className="row">
                  <div className="form-group col-6 mb-3">
                    <FormInput
                      placeholder="*********"
                      type="password"
                      id="password"
                      errorMessage="Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"
                      label="Password"
                      pattern={`^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`}
                      onChange={onChange}
                      name="password"
                      value={values["password"]}
                      autoComplete="new-password"
                    />
                      </div>
                      <div className="form-group col-6 mb-3">
                        <FormInput
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm Password"
                          errorMessage="Passwords don't match!"
                          label="Confirm Password"
                          pattern={values["password"]}
                          value={values["confirmPassword"]}
                          onChange={onChange}
                          required={values["password"] !== ''}
                        />
                      </div>
                </div>
              </div>
              <div className="col-4 mb-3">
                <FormInput
                  name="profileImage"
                  type="file"
                  placeholder="Upload an image"
                  label=" profile image"
                  value={values["profileImage"]}
                  setValues={setValues}
                  values={values}
                  setIsUploading={setIsUploading}
                  setPhotoUrl={setPhotoUrl}
                  photoUrl={photoUrl}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>

            <Button
              color="primary"
              className="mr-1"
              disabled={isSaving || isUploading ? true : false}
            >
              {isSaving ? (
                <Spinner style={{ height: "20px", width: "20px" }} />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Profile;
