import {Home, File, UserCheck, Compass, FileText, Users} from 'react-feather'
export const ADMIN_MENUITEMS = [
    {
        Items:[
            { path: `dashboard`,icon:Home, title: 'Dashboard', type: 'link',  active: false },
        
        ]
    },

    {
        Items:[
            { path: `admins`,icon:UserCheck, title: 'Admins', type: 'link',  active: false },
        
        ]
    },
    {
        Items:[
            { path: `companies`,icon:Compass, title: 'Companies', type: 'link',  active: false },
        
        ]
    },
    {
        Items:[
            { path: `employees`,icon:Users, title: 'Users', type: 'link',  active: false },
        
        ]
    },
    {
        Items:[
            { path: `doc-templates`,icon:FileText, title: 'Templates', type: 'link',  active: false },
        
        ]
    },
    {
        Items:[
            { path: `all-documents`,icon:FileText, title: 'All Templates', type: 'link',  active: false },
        
        ]
    }     
]