import { createCompanyService, deleteCompanyService, getCompanies, updateCompanyService } from "../../services/company.services";
import { createAction } from "../../utils/reducer.utils";
import { COMPANY_ACTION_TYPES } from "./company.types";

export const fetchCompaniesStart = () =>
    createAction(COMPANY_ACTION_TYPES.FETCH_COMPANIES_START);


export const fetchCompaniesSuccess = (companiesArray) =>
    createAction(COMPANY_ACTION_TYPES.FETCH_COMPANIES_SUCCESS, companiesArray);


export const fetchCompaniesFailed = (error) =>
    createAction(COMPANY_ACTION_TYPES.FETCH_COMPANIES_FAILED, error);

export const fetchCompaniesAsync = () => async (dispatch) => {
    dispatch(fetchCompaniesStart());

    try {
        const companiesData = await getCompanies();
        dispatch(fetchCompaniesSuccess(companiesData));
    } catch(error) {
        dispatch(fetchCompaniesFailed(error))
    }
}

export const loadMoreCompanies = (page = 1, companies) => async (dispatch) => {
    try {
      const result = await getCompanies(page);
      companies = {...result, data: [...companies.data, ...result.data ]};
      
      dispatch(fetchCompaniesSuccess(companies));
      
    } catch (error) {
        console.log("ERROR", error);
        dispatch(fetchCompaniesFailed(error))
    }
};

export const addCompany =  async (company, companiesArray) => {
    try {
        const res = await createCompanyService(company);
       createOrUpdateCompany(res.data.data, companiesArray);
       return res;
    } catch(error) {
        return error;
    }
}

const createOrUpdateCompany = async (company, companiesArray) => {
    const checkCompanyExists = companiesArray.find(comp => company.id === comp.id)
    if(checkCompanyExists) {
        const index = companiesArray.findIndex(comp => comp.id === company.id)
        companiesArray[index] = company;
    } else  {
        return companiesArray.unshift(company);
    }
}

// Update company 
export const updateCompany =  async (company, companiesArray, companyId) => {
    try {
        const res = await updateCompanyService(company, companyId);
        createOrUpdateCompany(res.data.data, companiesArray);
        return res;
    } catch(error) {
        console.log(error);
        return error;
    }
}

// Delete company
export const deleteCompany = async (companyId) => {
    try {
        const res = await deleteCompanyService(companyId);
        return res;
    } catch(error) {
        console.log(error);
        return error;
    }
}