import { createAdminService, deleteAdminService, getAllAdmin, updateAdminService } from "../../services/admin.services";
import { createAction } from "../../utils/reducer.utils";
import { ADMIN_ACTION_TYPES } from "./admin.types";

export const setCurrentAdmin = (admin) =>
  createAction(ADMIN_ACTION_TYPES.SET_CURRENT_ADMIN, admin);

export const setLastAdminDoc = (doc) =>
  createAction(ADMIN_ACTION_TYPES.SET_LAST_ADMIN_DOC, doc);

export const fetchAdminsStart = () =>
  createAction(ADMIN_ACTION_TYPES.FETCH_ADMINS_START);

export const fetchAdminsSuccess = (adminsArray) =>
  createAction(ADMIN_ACTION_TYPES.FETCH_ADMINS_SUCCESS, adminsArray);

export const fetchAdminsFailed = (error) =>
  createAction(ADMIN_ACTION_TYPES.FETCH_ADMINS_FAILED, error);

export const fetchAdminsAsync = () => async (dispatch) => {
   dispatch(fetchAdminsStart());

  try {
    const result = await getAllAdmin();
    dispatch(fetchAdminsSuccess(result));
    
  } catch (error) {
    dispatch(fetchAdminsFailed(error));
  }
};

export const searchAdminsAsync = (name = '' , sort = {} ) => async (dispatch) => {
  try {
    const result = await getAllAdmin(null, name, sort);
    dispatch(fetchAdminsSuccess(result));
    
  } catch (error) {
    dispatch(fetchAdminsFailed(error));
  }
};


export const loadMoreAdminDB = (page = 1, admins) => async (dispatch) => {
  try {
    const result = await getAllAdmin(page);
    admins = {...result, data: [...admins.data, ...result.data ]};
    
    dispatch(fetchAdminsSuccess(admins));
    
  } catch (error) {
      console.log("ERROR", error);
      dispatch(fetchAdminsFailed(error))
  }
};


export const addAdmin = async (admin, adminsArray) => {
  try {
    const res = await createAdminService(admin);
    createOrUpdateAdmin(res.data.data, adminsArray);
    return res;
  } catch(error) {
    console.log(error);
    return error;
  }
};

const createOrUpdateAdmin = async (admin, adminsArray) => {
  const checkAdminExists = adminsArray.find((ad) => admin.id === ad.id);
  if (checkAdminExists) {
    const index = adminsArray.findIndex((adm) => adm.id === admin.id);
    adminsArray[index] = admin;
  } else {
    return adminsArray.unshift(admin);
  }
};

// Update Admin
export const updateAdmin = async (admin, adminArray, adminId) => {
  try {
    const res = await updateAdminService(admin, adminId);
    createOrUpdateAdmin(res.data.data, adminArray);
    return res;
  } catch(error) {
    console.log(error);
    return error;
  }
};

// Delete Admin
export const deleteAdmin = async (adminId) => {
  try {
    const res = await deleteAdminService(adminId);
    return res;
  } catch(error) {
    console.log(error);
    return error;
  }
};


// Update Admin Profile 
export const updateAdminProfile = async(adminObj, id) =>  {
  try {
    const res = await updateAdminService(adminObj, id);
    return res;
  } catch(error) {
    console.log(error);
    return error;
  }
};