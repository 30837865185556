
import { useEffect, useState } from 'react';
import './custom-search-bar.styles.scss';


const CustomSearchBar = ({ setQuery, placeholder, keyUpFunc }) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      if (keyUpFunc) {
        keyUpFunc()
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [inputValue])

  return (
    <div className="row mt-2 p-2 custom-search-bar-container shadow-sm mb-4">
      <div className="input-group">
        <input
          type="search"
          placeholder={placeholder}
          aria-describedby="button-addon1"
          value={inputValue}
          className="form-control border-0 bg-transparent"
          onChange={(event) => {setQuery(event.target.value); setInputValue(event.target.value); }}
        />
        <div className="input-group-append">
          <button
            id="button-addon1"
            type="button"
            className="btn btn-link text-warning"
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomSearchBar;