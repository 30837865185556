import { Spinner } from 'reactstrap';
import './admin-list.styles.scss';
import { Plus } from 'react-feather';
import AdminCard from '../admin-card/admin-card.component';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdmins, selectCurrentAdmin } from '../../../store/admin/admin.selector';
import { useEffect, useState } from 'react';
// import { getTotalAdmin } from '../../../services/auth-firebase.services';
import { loadMoreAdminDB, searchAdminsAsync } from '../../../store/admin/admin.action';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomSearchBar from '../../custom-search-bar/custom-search-bar.component';
import SortIcon from '../../custom-sort-icon/custom-sort-icon.component';

const AdminList = ({ toggle, setAdminFormData, isLoading }) => {
  const [ totalAdmin, setTotalAdmin] = useState(0);

  const currentAdmin = useSelector(selectCurrentAdmin);
  const allAdmins = useSelector(selectAdmins);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState();

  const handleSort = (column) => {
    console.log("Column sortColumn", column, sortColumn,sortColumn === column )
    if (sortColumn === column) {
      // Toggle sort direction if same column clicked again
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      dispatch(searchAdminsAsync(query, { [column]: sortDirection ? sortDirection : 'asc'  }));
    } else {
      // Set new sort column and direction
      setSortColumn(column);
      setSortDirection('asc');
      dispatch(searchAdminsAsync(query, { [column]: sortDirection ? sortDirection : 'asc'  }));
    }    

  };


  const loadMoreAdmin = () => {
    if (allAdmins.meta.current_page < allAdmins.meta.last_page) {
      dispatch(loadMoreAdminDB(allAdmins.meta.current_page + 1, allAdmins))
    }
  }


  const searchAdmin = () => {
    dispatch(searchAdminsAsync(query))
  }

  return (
    <>
      <div className="package-list-view">
      <CustomSearchBar placeholder="Search here..." setQuery={setQuery} keyUpFunc={searchAdmin} />
        <div className="row mt-2">
          <div className="col-md-2" onClick={() => handleSort('name')}>
            <span className="product-list-table-head-text">Name {sortColumn === 'name' ? <SortIcon direction={sortDirection} />: '️↕️'} </span>

          </div>
          
          <div className="col-md-2" onClick={() => handleSort('email')}>
            <span className="product-list-table-head-text">Email {sortColumn === 'email' ? <SortIcon direction={sortDirection} />: '️↕️'} </span>
          </div>
          <div className={` ${(!currentAdmin?.user.role.includes('Admin')) ? 'col-md-2': 'col-md-2'}`} onClick={() => handleSort('role')}>
            <span className="product-list-table-head-text">Role {sortColumn === 'role' ? <SortIcon direction={sortDirection} />: '️↕️'} </span>
          </div>
          <div className={` ${(!currentAdmin?.user.role.includes('Admin')) ? 'col-md-2': 'col-md-1'}`} onClick={() => handleSort('status')}>
            <span className="product-list-table-head-text">Status {sortColumn === 'status' ? <SortIcon direction={sortDirection} />: '️↕️'} </span>
          </div>
          <div className="col-md-2">
            <span className="product-list-table-head-text">Created At</span>
          </div>
          <div className="col-md-2">
            <span className="product-list-table-head-text">Updated At</span>
          </div>
          
          { (currentAdmin?.user.role.includes('Admin')) && <div className="col-md-1"> Action </div>}
        </div>
        {
        isLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : 

        allAdmins?.meta?.total &&  <InfiniteScroll
          dataLength={ allAdmins.data.length }
          next={loadMoreAdmin}
          hasMore={ allAdmins.meta.current_page < allAdmins.meta.last_page }
          loader={<div className='primary-color text-center'> loading... </div>}
          scrollableTarget="scrollableDiv"
          endMessage={
            <p className='text-center primary-color'>
              <b> You have seen it all</b>
            </p>
          }
        >
           {(allAdmins && allAdmins.data.length > 0 ? (
            allAdmins?.data.map((adminObj, index) => (
              <AdminCard
                key={adminObj.id}
                adminObj={adminObj}
                setAdminFormData={setAdminFormData}
                toggle={toggle}
              />
            ))
          ) : '')}
        </InfiniteScroll>
      
        }
        
      </div>
    </>
  );
};

export default AdminList;
