import axios from "axios";
import { API_BASE_URL } from "../constant/constant";
import { removeUndefinedNull } from "../utils/helper.utils";

export const getEmployees = async (page = 1, q = '', sort = {}, companyId = "", url = "/employees") => {
    const allEmployee = await axios.get(API_BASE_URL + url,
    {
        params: {
          q: q,
          page: page,
          sort: sort,
          companyId: companyId
        }
    }
    );
    return allEmployee.data;
}

export const createEmployeeService = (data) => {
    return axios.post(API_BASE_URL + '/employees', data);  
}

export const updateEmployeeService = async (data, id) => {
    const { confirmPassword, createdAt, updatedAt,  ...rest } = removeUndefinedNull(data);
    const employee = await axios.post(API_BASE_URL + `/employees/${id}`, {
        ...rest,
        _method: 'patch'  
    });

    return employee;
}

// update many employees 
export const updateManyEmployeeService = async (employees, status) => {
    const employee = await axios.post(API_BASE_URL + `/update-many-employees`, {
        employees: employees,
        status: status,
        _method: 'patch'  
    });

    return employee;
}
export const deleteEmployeeService = async (id) => {
    const employee = await axios.post(API_BASE_URL + `/employees/${id}`,{
        _method: 'delete'  
    });

    return employee;
}

