import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import "./admin-form.styles.scss";
import {
  addAdmin,
  deleteAdmin,
  updateAdmin,
} from "../../../store/admin/admin.action";
import { selectAdmins, selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { showServerErrorMessage } from "../../../utils/show-server-error-message";

const AdminForm = ({ toggle, adminObj = {} }) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    profileImage: "",
    password: "",
    confirmPassword: "",
    status: "1",
  });

  const [isSaving, setIsSaving] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const allAdmin = useSelector(selectAdmins);
  const [isUploading, setIsUploading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [focused, setFocused] = useState(false);

  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    if (Object.keys(adminObj).length > 0) {
      const { id, ...rest } = adminObj;
      setValues({ ...values, ...rest });
      setAdminId(id);
      setPhotoUrl(adminObj.profileImage ? adminObj.profileImage : null);
    }
  }, [adminObj]);

  const resetFormFields = () => {
    setValues({
      name: "",
      email: "",
      profileImage: "",
      password: "",
      confirmPassword: "",
      status: 1,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    values.profileImage = photoUrl ? photoUrl : null;
    try {
      if (adminId) {
        const res = await updateAdmin(
          { ...values, id: adminId },
          allAdmin.data,
          adminId
        );
        if (res.status && (res.status === 200 || res.status === 201)) {
          resetFormFields();
          toast.success(`Admin details updated successfully!`);
          toggle();
        } else {
          showServerErrorMessage(res.response);
        }
        setIsSaving(false);
      } else {
        const res = await addAdmin(values, allAdmin.data);

        if (res.status && (res.status === 200 || res.status === 201)) {
          resetFormFields();
          toast.success(`Admin details added successfully!`);
          toggle();
        } else {
          showServerErrorMessage(res.response);
        }

        setIsSaving(false);
      }
      
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        toast.error("Can not create user, email already in use");
      } else {
        toast.error("User creation encountered an error");
      }

      console.log("Form catched Error", err);

      setIsSaving(false);
    }
  };

  const handleFocus = (e) => {
    e.target.value === "" ? setFocused(true) : setFocused(false);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Handle Delete Admin
  const handleDeleteAdmin = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deactive an admin, he/she can't loggin to system again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        updateAdmin({ ...adminObj, status: 0 }, allAdmin.data, adminId).then(
          () => {
            SweetAlert.fire(
              "Deactivated!",
              "Your admin has been deactivated.",
              "success"
            );
            // const index = allAdmin.findIndex((alb) => alb.id === adminId);
            // allAdmin.splice(index, 1);
            toggle();
          },
          (err) => {
            SweetAlert.fire(
              "Admin is not deactivated",
              "Your admin has not been deactivated.",
              "error"
            );
          }
        );
      } else {
        SweetAlert.fire("Your admin is active!");
      }
    });
  };

  return (
    <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
      <div className="form-row row mb-3">
        <div className="col-12">
          <div className="row">
            <div className="col-8">
              <div className="form-group mb-3">
                <label className="col-form-label">Name</label>
                <FormInput
                  type="text"
                  id="name"
                  onChange={onChange}
                  name="name"
                  placeholder="ABCDEF"
                  value={values["name"]}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label className="col-form-label">Email Address</label>
                <FormInput
                  type="email"
                  id="email"
                  onChange={onChange}
                  name="email"
                  placeholder="Test@gmail.com"
                  value={values["email"]}
                  required
                />
              </div>
            </div>
            <div className="col-4 mb-3">
              <FormInput
                name="profileImage"
                type="file"
                placeholder="Upload an image"
                label="Admin profile image"
                uploadDirectory="Admin profiles"
                value={values["profileImage"]}
                setValues={setValues}
                values={values}
                setIsUploading={setIsUploading}
                setPhotoUrl={setPhotoUrl}
                photoUrl={photoUrl}
                onChange={onChange}
              />
            </div>
          </div>
        </div>

        {/* <div className="col-12 mb-3">
          <label htmlFor="Role">Select admin role.</label>
          <select
            className="form-select"
            name="role"
            value={values["role"]}
            onChange={(e) => {
              onChange(e);
              handleFocus(e);
            }}
            id="role"
            required
            onBlur={(e) => handleFocus(e)}
            focused={focused.toString()}
          >
            <option value="">Select Role...</option>
            <option value="Admin">Admin</option>
            <option value="Editor">Editor</option>
            <option value="Viewer">Viewer</option>
          </select>
          <span className="form-error-span">Please select one admin role.</span>
        </div> */}
        { currentAdmin.user?.role === 'Super Admin' &&
          <div className="col-12 mb-3">
            <label htmlFor="status">Select admin status.</label>
            <select
              className="form-select"
              name="status"
              value={values["status"]}
              onChange={(e) => {
                onChange(e);
                handleFocus(e);
              }}
              id="status"
              required
              onBlur={(e) => handleFocus(e)}
              focused={focused.toString()}
            >
              <option value="1">Active</option>
              <option value="0">Deactive</option>
            </select>
          </div>
        }
        {
        (!adminId || (currentAdmin?.user?.type === 'Admin' && currentAdmin.user?.role.includes("Admin"))) && <>
          <div className="form-group col-6 mb-3">
            <FormInput
              placeholder="*********"
              type="password"
              id="password"
              errorMessage="Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"
              label="Password"
              pattern={`^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`}
              onChange={onChange}
              name="password"
              value={values["password"]}
              autoComplete="new-password"
            />
          </div>
          <div className="form-group col-6 mb-3">
            <label className="col-form-label">Confirm Password</label>
            <FormInput
              name="confirmPassword"
              type="password"
              errorMessage="Passwords don't match!"
              pattern={values["password"]}
              required={values["password"] !== ''}
              value={values["confirmPassword"]}
              onChange={onChange}
            />
          </div>
        </>
        }
      </div>
      <div
        className={
          adminId && currentAdmin.user?.role === 'Super Admin' && !(!adminObj.status || adminObj.status != 1)
            ? "d-flex justify-content-between"
            : ""
        }
      >
        {adminId && currentAdmin.user?.role === 'Super Admin' && adminObj.status && adminObj.status == 1 ? (
          <Button color="secondary" onClick={handleDeleteAdmin}>
            Deactive
          </Button>
        ) : (
          ""
        )}
        <div
          className={
            !adminObj.status || adminObj.status != 1
              ? "d-flex justify-content-between"
              : "d-flex justify-content-between"
          }
        >
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="mr-1"
            disabled={isSaving ? true : false}
          >
            {isSaving ? (
              <Spinner style={{ height: "20px", width: "20px" }} />
            ) : !adminObj.status || adminObj.status != 1 ? (
              "Save"
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default AdminForm;
