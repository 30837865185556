import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import "./employee-form.styles.scss";
import {
  addEmployee,
  deleteEmployee,
  updateEmployee,
} from "../../../store/employees/employee.action";
import { showServerErrorMessage } from "../../../utils/show-server-error-message";
import { selectEmployees } from "../../../store/employees/employee.selector";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { getAllEmployeeDocuments } from "../../../services/employee-documents.services";
const EmployeeForm = ({ toggle, employeeObj = {} }) => {
  const { companyId } = useParams();
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    employeeType: "Regular",
    profileImage: "",
    status: 1,
    documents: null,
    companyId
  });

  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [documents, setDocuments] = useState([]);

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [employeeId, setEmployeeId] = useState(null);
  const allEmployee = useSelector(selectEmployees);

  const currentAdmin = useSelector(selectCurrentAdmin);

  const [focused, setFocused] = useState(false);
  useEffect(() => {
    if (Object.keys(employeeObj).length > 0) {
      const { id, ...rest } = employeeObj;
      setValues({ ...values, ...rest });
      setEmployeeId(id);
      setPhotoUrl(employeeObj.profileImage ? employeeObj.profileImage : null);
    }
  }, [employeeObj, values]);

  useEffect(() => {
    const getDocs = async () =>
      await getAllEmployeeDocuments().then((result) => {
        if (result && result.data.length > 0) {
          // let ourDocs = result.data.filter((doc) => doc.company_id != null);
          // let adminDocs = result.data.filter((doc) => doc.company_id == null);
          const options = [
            {
              id: new Date().getTime() + Math.random() * 1000,
              // label: 'Our Document Templates',
              options: result.data.map((doc) => {
                // if (doc.company_id !== null) {
                  return { value: doc.id, label: doc.title }
                // }
              })
            },
            // {
            //   id: new Date().getTime()+ Math.random() * 1000,
            //   label: 'Admin Document Templates',
            //   options: adminDocs.map((doc) => {
            //     if (doc.company_id == null) {
            //       return { value: doc.id, label: doc.title }
            //     }
            //   })
            // },
            
          ];
          setDocuments(options);
        }
      });

    getDocs();
  }, []);


  const resetFormFields = () => {
    setValues({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      employeeType: "",
      profileImage: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    values.profileImage = photoUrl ? photoUrl : null;
    
    if (selectedDocuments.length > 0) {
      values.documents = selectedDocuments.map( doc => doc.value );
    }

    try {
      if (employeeId) {
        const res = await updateEmployee(
          { ...values, id: employeeId },
          allEmployee.data,
          employeeId
        );

        if (res.status && (res.status === 200 || res.status === 201)) {
          resetFormFields();
          toast.success(`Employee details updated successfully!`);
          toggle();
        } else {
          showServerErrorMessage(res.response);
        }
        setIsSaving(false);
      } else {
        const res = await addEmployee(values, allEmployee.data);
        if (res.status && (res.status === 200 || res.status === 201)) {
          resetFormFields();
          toast.success(`Employee added successfully!`);
          toggle();
        } else {
          showServerErrorMessage(res.response);
        }
        setIsSaving(false);
      }
      
    } catch (err) {
      let { errors } = err;
      for (const error in errors) { 
        if (errors[error].length > 0) {
            for (const err of errors[error]) {
                toast.error(err);
            }
        }
      }
      toast.error("User creation encountered an error");
      setIsSaving(false);
    }
  };

  const handleFocus = (e) => {
    e.target.value === "" ? setFocused(true) : setFocused(false);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };


  const handleDeleteEmployee = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deactive an employee, he/she can't loggin to system again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const res = await deleteEmployee(
          {id: employeeId },
          allEmployee.data,
          employeeId);

          
        if (res.status && (res.status === 200 || res.status === 201)) {
          SweetAlert.fire(
            "deleted!",
            "Your employee has been deleted.",
            "success"
          );

        } else {
          SweetAlert.fire(
            "employee is not deleted",
            "Your employee has not been deleted.",
            "error"
          );
        }
      } else {
        SweetAlert.fire("Your employee is active!");
        toggle();
      }
    });
  };

  return (
    <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
      <div className="form-row row mb-3">
        <div className="col-8">
          <div className="row mb-3">
            <div className="form-group col-12">
              <label className="col-form-label">Name</label>
              <FormInput
                type="text"
                id="name"
                onChange={onChange}
                name="name"
                placeholder="ABCDEF"
                value={values["name"]}
                required
              />
            </div>
            <div className="form-group col-12">
              <label className="col-form-label">Email Address</label>
              <FormInput
                type="email"
                id="email"
                onChange={onChange}
                name="email"
                placeholder="Test@gmail.com"
                value={values["email"]}
                required
              />
            </div>
          </div>
        </div>

        <div className="col-4">
          <FormInput
            name="Image"
            type="file"
            placeholder="Upload an image"
            label="Employee image"
            uploadDirectory="Employees"
            value={values["profileImage"]}
            setValues={setValues}
            values={values}
            setIsUploading={setIsUploading}
            setPhotoUrl={setPhotoUrl}
            photoUrl={photoUrl}
            onChange={onChange}
          />
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="employeeType">Select Employee Type.</label>
          <select
            className="form-select"
            name="employeeType"
            value={values["employeeType"]}
            onChange={(e) => {
              onChange(e);
              handleFocus(e);
            }}
            id="employeeType"
            required
            onBlur={(e) => handleFocus(e)}
            focused={values["employeeType"] == "" && focused.toString() ? 'true' : 'false'} 
          >
            <option value="Admin">Admin</option>
            <option value="Regular">Regular</option>
            <option value="PIC">Person in Charge</option>
            <option value="MR">Management Representative</option>
            <option value="DC">Document Controller</option>
            <option value="IA">Internal Auditor Team</option>
            <option value="RM">Risk Manager</option>
            <option value="HR">Human Resource Manager</option>
            <option value="AM">Asset Manager</option>
            <option value="IM">Incident Manager</option>
            <option value="CM">Change Manager</option>
            <option value="BM">Backup Manager</option>
            <option value="BCM">Business Continuity Manager</option>
          </select>
          <span className="form-error-span">
            Please select one employee type.
          </span>
        </div>
        {(( currentAdmin?.user?.type !== 'Admin'  && !employeeId && values["employeeType"] !== "Admin")) && (
          <div className="col-12 mb-3">
            <label htmlFor="">Select a document for employee.</label>
              <Select
                options={documents}
                value={selectedDocuments}
                onChange={(data)=> setSelectedDocuments(data)}
                isSearchable={true}
                isMulti

              />
          </div>
        )}
        {(!employeeId || (currentAdmin?.user?.type === 'Admin' && currentAdmin?.user.role.includes('Admin'))) && (
          <>
            <div className="form-group col-6 mb-3">
              <FormInput
                placeholder="*********"
                type="password"
                id="password"
                errorMessage="Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"
                label="Password"
                pattern={`^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`}
                onChange={onChange}
                name="password"
                value={values["password"]}
                autoComplete="new-password"
              />
            </div>
            <div className="form-group col-6 mb-3">
              <FormInput
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                errorMessage="Passwords don't match!"
                label="Confirm Password"
                pattern={values["password"]}
                required={values["password"] !== ''}
                value={values["confirmPassword"]}
                onChange={onChange}
              />
            </div>
          </>
        )}
      </div>
      <div className={employeeId && (currentAdmin?.user?.type === 'Employee' || (currentAdmin?.user?.type === 'Admin' && currentAdmin?.user.role.includes('Super Admin'))) ? "d-flex justify-content-between" : ""}>
        {employeeId && (currentAdmin?.user?.type === 'Employee' || (currentAdmin?.user?.type === 'Admin' && currentAdmin?.user.role.includes('Super Admin'))) && (
          <Button
            color="secondary"
            onClick={() => {
              handleDeleteEmployee();
              toggle();
            }}
          >
            Delete
          </Button>
        )}
        <div
          className={
            !(currentAdmin?.user?.type === 'Admin' && currentAdmin?.user.role.includes('Super Admin')) ||
            !employeeObj.status || employeeObj.status != 1
              ? "d-flex justify-content-between"
              : "d-flex gap-2"
          }
        >
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="mr-1"
            disabled={isSaving ? true : false}
          >
            {isSaving ? (
              <Spinner style={{ height: "20px", width: "20px" }} />
            ) : !employeeObj.Status || employeeObj.Status != 1 ? (
              "Save"
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default EmployeeForm;
