import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Button,
} from "reactstrap";
import { useEffect, useState } from "react";
import CustomBackButton from "../../custom-back-button/custom-back-button.component";
import Breadcrumbs from "../../../layout/breadcrumb";
import CustomModal from "../../custom-modal/custom-modal.component";
import { Plus } from "react-feather";
import { createDocumentFromTemplateService, getAllDocuments } from "../../../services/documents.services";
import DocumentTemplateForm from "./document-template-form/document-template-form.component";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const DocumentTemplates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allDocuments, setAllDocuments] = useState([]);
  const [documentObj, setDocumentObj] = useState({});
  const [id, setId] = useState(null);
  const [documentDetails, setDocumentDetails] = useState(null);

  const[isSaving, setIsSaving] = useState([]);
  
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const currentAdmin = useSelector(selectCurrentAdmin);


  useEffect(() => {
    setIsLoading(true);
    const getDocs = async () =>
      await getAllDocuments()
        .then((result) => {
          setDocumentDetails(result);
          if (result && result?.data.length > 0) {
            setAllDocuments(
              result.data.filter((document) => {
                if (
                  currentAdmin?.user.type === "Employee"
                ) {
                  return document.company_id != null;
                } else return document;
              })
            );
          }

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });

    getDocs();
  }, []);

  const createDocfromTemplate = async (e, id) => {
    e.stopPropagation();
    setIsSaving([id]);
      const result = await createDocumentFromTemplateService(id);
      console.log(result.data.data);
      if (result.status === 200 || result.status === 201) {
        toast.success("Document successfully created");
      } else {
        toast.success("Document can't be created at this time, please try again later!");
      }

      setIsSaving([]);
  };

  return (
    <>
      <Breadcrumbs parent="Dashboard" title="Document Templates" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="px-0 bg-transparent d-flex justify-content-between align-items-center">
                <h5 className="d-flex gap-1 m-0 p-0">
                  <CustomBackButton /> Document Templates
                </h5>
                {
                  ((currentAdmin?.user.type === 'Admin' && currentAdmin?.user.role.includes('Admin')) || 
                  currentAdmin?.user.type === 'Employee') && 
                  <div className="add-new-employee">
                  <Button
                    color="primary"
                    outline={true}
                    className="d-flex gap-1 "
                    onClick={() => {
                        setDocumentObj({});
                        setId(null);
                        toggle();
                    }}
                  >
                    <Plus /> Add New Template
                  </Button>
                </div>}
              </CardHeader>
              <CardBody className="main-content-card-body" id="scrollableDiv">
                <Row>
                  <table className="table align-baseline">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Body</th>
                        <th>Created By</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        { currentAdmin?.user.type === "Employee" && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {
                      allDocuments.map((doc, index) => (
                        <tr
                          key={doc.id}
                          className="main-list-hover"
                          onClick={() => {
                            setId(doc.id);
                            setDocumentObj(doc);
                            toggle();
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>{doc.title}</td>
                          <td>
                            <div dangerouslySetInnerHTML={{ __html: doc.body && doc.body.substr(0, 100) }}></div>
                          </td>
                          <td>{doc.createdBy?.email ? doc.createdBy?.email : currentAdmin.user?.email}</td>
                          <td>{doc.createdAt}</td>
                          <td>{doc.updatedAt}</td>
                          { currentAdmin?.user.type === "Employee" && <td>
                              <Button size="sm" color="primary" onClick={(e) => createDocfromTemplate(e, doc.id) } disabled={ isSaving.indexOf(doc.id) !== -1 }>
                                { isSaving.indexOf(doc.id) !== -1 ? <Spinner /> : "Create Document"}
                              </Button>
                            </td> }
                        </tr>
                      )) 
                    
                    }

                      {isLoading && (
                        <tr>
                          <td colSpan={6}>
                            <div className="text-center">
                              <Spinner />
                            </div>
                          </td>
                        </tr>
                      )}

                      {!isLoading && allDocuments.length === 0 && (
                        <tr>
                          <td colSpan={6}>
                            <div className="text-center">
                              <p>Document templates list is empty.</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <CustomModal
          toggle={toggle}
          modal={modal}
          modalTitle={"Add | Edit Document"}
          size="xl"
        >
          <DocumentTemplateForm
            toggle={toggle}
            allDocuments={allDocuments}
            id={id}
            documentObj={documentObj}
          />
        </CustomModal>
      </Container>
    </>
  );
};

export default DocumentTemplates;
