import { createSelector } from "reselect";

const selectEmployeeReducer = (state) => state.employees;

export const selectEmployees = createSelector(
    [selectEmployeeReducer],
    (employeeSlice) => {
        return employeeSlice.employees;
    }
)

export const selectEmployeesIsLoading = createSelector(
    [selectEmployeeReducer],
    (employeesSlice) => employeesSlice.isLoading
)