import { createEmployeeService, deleteEmployeeService, getEmployees, updateEmployeeService } from "../../services/employee.services";
import { createAction } from "../../utils/reducer.utils";
import { EMPLOYEE_ACTION_TYPES } from "./employee.types";

export const fetchEmployeesStart = () =>
    createAction(EMPLOYEE_ACTION_TYPES.FETCH_EMPLOYEES_START);


export const fetchEmployeesSuccess = (employeesArray) =>
    createAction(EMPLOYEE_ACTION_TYPES.FETCH_EMPLOYEES_SUCCESS, employeesArray);


export const fetchEmployeesFailed = (error) =>
    createAction(EMPLOYEE_ACTION_TYPES.FETCH_EMPLOYEES_FAILED, error);

export const fetchEmployeesAsync = (page = 1, q = '', sort = {} , companyId = '', url = "/employees") => async (dispatch) => {
    dispatch(fetchEmployeesStart());

    try {
        const employeesArray = await getEmployees(page, q, sort, companyId, url);
        dispatch(fetchEmployeesSuccess(employeesArray));
    } catch(error) {
        dispatch(fetchEmployeesFailed(error))
    }
}

export const loadMoreEmployeeDB = (page = 1, employees, q = '', companyId = '') => async (dispatch) => {
    try {
      const result = await getEmployees(page, q, {}, companyId);
      employees = {...result, data: [...employees.data, ...result.data ]};
      dispatch(fetchEmployeesSuccess(employees));
    } catch (error) {
        dispatch(fetchEmployeesFailed(error))
    }
  };

export const addEmployee =  async (employee, employeesArray = []) => {
    
    try {
        const res = await createEmployeeService(employee);
        
        if (employeesArray.length > 0) {
            employeesArray.unshift(res.data.data);
        } else {
            employeesArray.push(res.data.data);
        }
       return res;
    } catch(error) {
        console.log("ERROR", error);
        return error;
    }
}

// Update employee 
export const updateEmployee =  async (employee, employeesArray, employeeId) => {
    try {
        const res = await updateEmployeeService(employee, employeeId);
        const checkEmployeeExists = employeesArray.find(emp => employee.id === emp.id)
        if(checkEmployeeExists) {
            const index = employeesArray.findIndex(emp => emp.id === employee.id)
            employeesArray[index] = employee;
        }
       return res;
    } catch(error) {
        return error;
    }
}

// Delete employee
export const deleteEmployee = async (employee, employeesArray, employeeId)  => {
    try {
        const res = await deleteEmployeeService(employeeId);
        const checkEmployeeExists = employeesArray.find(emp => employee.id === emp.id)
        if(checkEmployeeExists) {
            const index = employeesArray.findIndex(emp => emp.id === employee.id)
            employeesArray.splice(index, 1);
        }
        return res;
    } catch(error) {
        console.log(error);
        return error;
    }
}

// Update Employee Profile 
export const updateEmployeeProfile = async(employeeObj, id) =>  {
    try {
      const res = await updateEmployeeService(employeeObj, id);
      return res;
    } catch(error) {
      console.log(error);
      return error;
    }
};