
const SortIcon = ({ direction }) => {
    if (direction === 'asc') {
        return "⬆️";
    } else if (direction === 'desc') {
        return "⬇️";
    } else {
        return '️↕️';
    }
};

export default SortIcon;