import axios from "axios";
import { API_BASE_URL } from "../constant/constant";
import { removeUndefinedNull } from "../utils/helper.utils";

export const getAllEmployeeDocuments = async (page = 1, q = '', docStatus = 1, sort = {} ) => {
    const allDocuments = await axios.get(API_BASE_URL + '/documents', {
        params: {
          q: q,
          status: docStatus,
          page: page,
          sort: sort
        }
        });

    return allDocuments.data;
}

export const createEmployeeDocumentService = async (data) => {
    const document = await axios.post(API_BASE_URL + '/documents', data);

    return document;
}

export const updateEmployeeDocumentService = async (data, id) => {
    const { createdAt, updatedAt, ...rest } = removeUndefinedNull(data);
    const document = await axios.post(API_BASE_URL + `/documents/${id}`, {
        ...rest,
        _method: 'patch'  
    });

    return document;
}


export const deleteEmployeeDocumentService = async (id) => {
    const document = await axios.post(API_BASE_URL + `/documents/${id}`, {
        _method: 'delete'  
    });

    return document;
}

export const deleteDocumentEmployeeService = async (id, employeeId) => {
    const document = await axios.post(API_BASE_URL + `/delete-document-employee/${id}`,{
        employee: employeeId,
        _method: 'delete'  
    });

    return document;
}