import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Col } from 'reactstrap';
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);


const EmployeeDashboard = ({dashboardObj}) => {
    const pieData = {
        labels: ['Open Docs', 'Pending Docs', 'Approved Docs'],
        datasets: [
          {
            label: 'Total',
            data: [
                dashboardObj?.totalDocument?.find(({status}) => status == 1 )?.total,
                dashboardObj?.totalDocument?.find(({status}) => status == 2 )?.total,
                dashboardObj?.totalDocument?.find(({status}) => status == 3 )?.total
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',  
            ],
            borderWidth: 1,
          },
        ],
    };
  
    return (
        <>
            <Col sm="6" className="mb-4 text-center offset-sm-3" >
                <Pie data={pieData} />
            </Col>
        </>
    )
}

export default EmployeeDashboard;