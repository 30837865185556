import axios from "axios";
import { API_BASE_URL } from "../constant/constant";
import { removeUndefinedNull } from "../utils/helper.utils";

export const getCompanies = async (page = 1, q = '') => {
    const allCompany = await axios.get(API_BASE_URL + '/companies',
    {
        params: {
          q: q,
          page: page
        }
    }
    );

    return allCompany.data;
}

export const createCompanyService = async (data) => {
    const company = await axios.post(API_BASE_URL + '/companies', data);

    return company;
}

export const updateCompanyService = async (data, id) => {
    const { password, confirmPassword, createdAt, updatedAt,  ...rest } = removeUndefinedNull(data);
    const company = await axios.post(API_BASE_URL + `/companies/${id}`, {
        ...rest,
        _method: 'patch'  
    });
    return company;
}


export const deleteCompanyService = async (id) => {
    const company = await axios.post(API_BASE_URL + `/companies/${id}`,{
        _method: 'delete'  
    });

    return company;
}

