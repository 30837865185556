// Format Byties to KB and MB
export const formatBytes = (bytes, decimals = 0) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// remove undefined and null values from an object
export const removeUndefinedNull = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
};

// change timestamps to readable date strings
export const formatTimestap = (timestamps) => {
  const newDate = new Date(
    timestamps.seconds * 1000 + timestamps.nanoseconds / 1000000
  );
  // 4/29/2023
  return (
    newDate.getDay() + "/" + newDate.getMonth() + "/" + newDate.getFullYear()
  );
};
