import { useSelector } from "react-redux";
import "./admin-card.styles.scss";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
const AdminCard = ({ adminObj, setAdminFormData, toggle }) => {
  const { name, email, role, id, status, createdAt, updatedAt } = adminObj;

  const currentAdmin = useSelector(selectCurrentAdmin);
  
  const handleOpenAdminFormModal = () => {
    if(currentAdmin?.user.id !== adminObj.id && adminObj.role != 'Super Admin' && currentAdmin?.user.role.includes('Admin') ) {
      setAdminFormData(adminObj);
      toggle();
    }
  };
  return (
    <>
      <div
        className="admin-list-item position-relative row align-items-center main-list-hover"
        onClick={handleOpenAdminFormModal}
      >
        <div className="col-md-2">
          <div className="d-flex align-items-center gap-2 position-relative">
            <span className="admin-list-name">{name}</span>
            <div className="admin-list-color"></div>
          </div>
        </div>
        <div className="col-md-2 d-flex overflow-hidden">
          <p className="admin-list-description">
            {email}
          </p>
        </div>
        <div className={`${(!currentAdmin?.user.role.includes('Admin')) ? 'col-md-2 ': 'col-md-2 ' } d-flex`}>
          <p className="admin-list-description">
            {role}
          </p>
        </div>
        <div className={` ${(!currentAdmin?.user.role.includes('Admin')) ? 'col-md-2 ': 'col-md-1 '} d-flex`}>
          <p className="admin-list-description">
            {status && status == 1 ? <span className="primary" >Active</span>: <span className="text-danger" >Deactive</span> } 
          </p>
        </div>
        <div className="col-md-2 d-flex">
          <p className="admin-list-description">
            {createdAt}
          </p>
        </div>
        <div className="col-md-2 d-flex">
          <p className="admin-list-description">
            {updatedAt}
          </p>
        </div>
        
        {(currentAdmin?.user.role.includes('Admin')) && <div className="col-md-1 d-flex">
          <div className="d-flex flex-column">
            <div className="admin-list-edit">
              <button> {currentAdmin?.user.id !== adminObj.id ? (
                "Edit"
              ) : "Me"}</button>
            </div>
          </div>
        </div>}
        
      </div>
    </>
  );
};

export default AdminCard;
