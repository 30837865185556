// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { storage } from "../../config/firebase.config";
import { useState, useEffect } from "react";
import "./file-input.styles.scss";
import { Film, Music, Upload } from "react-feather";
import { formatBytes } from "../../utils/helper.utils";
import { fileUpload } from "../../services/file-upload.services";

export const FileInput = ({
  setIsUploading,
  setPhotoUrl,
  photoUrl = "",
  name,
  placeholder,
  productFile = {},
}) => {
  const [percent, setPercent] = useState(0); // Handle file upload event and update state
  const [showProgress, setShowProgress] = useState(false); // Handle progress
  const [fileUrl, setFileUrl] = useState("");
  const [selectedFileDetails, setselectedFileDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Handle

  useEffect(() => {
    if( photoUrl !== '' && photoUrl !== null ) {
      setFileUrl(photoUrl);
    }

    if( Object.keys(productFile).length > 0 ) {
      setselectedFileDetails({ ...productFile });
    } 
    setIsLoading(false);
  }, [photoUrl, productFile]);

  function handleChange(event) {
    handleUpload(event.target.files[0]);
  }

  function handleUpload(file) {
    if (file) {
      setFileUrl(null);
      setselectedFileDetails({...selectedFileDetails, name: file.name, size: file.size, type: file.type });
      setShowProgress(true);
      fileUpload(file, setPercent).then((result) => {
        setShowProgress(false);
        setFileUrl(result.data.url);
        setShowProgress(false); 
        
        setPhotoUrl(result.data.url);
        
        setPercent(0);
        setIsUploading(false);
      }).catch((error) => { console.error(error); });
    }
  }

  return (
    <>
      <input
        type="file"
        id={name}
        className="d-none"
        onChange={handleChange}
        accept="image/*"
      />
      {
        !isLoading && (
            <label
              className="d-flex flex-column align-items-center photo-input-label"
              htmlFor={name}
            >
              <div className="w-avatar-container">
                {fileUrl && fileUrl != null ? (
                  <img src={fileUrl} className="" alt="" />
                ) : (
                  ""
                )}
              </div>
              {showProgress ? (
                <div className="loader-avatar-image">{percent}%</div>
              ) : (
                ""
              )}
              <span className="primary-color">{placeholder}</span>
            </label>
        ) 
      }
    </>
  );
};
