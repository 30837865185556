import Breadcrumbs from "../../layout/breadcrumb";
import {
  Card,
  Container,
  Row,
} from "reactstrap";
import "./dashboard.styles.scss";
import { useEffect, useState } from "react";

import { getDashboardStatistics } from "../../services/dashboard.services";
import CompanyDashboard from "./company-dashboard.component";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import CustomDataLoader from "../custom-data-loader/custom-data-loader.component";
import EmployeeDashboard from "./employee-dashboard.component";
import AdminDashboard from "./admin-dashboard.component";

const Dashboard = () => {
  const [dashboardObj, setDashboardObject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    const getDashboardData = async () => {
      const data = await getDashboardStatistics();
      setDashboardObject(data["data"]);
      setIsLoading(false);
    };

    getDashboardData();
  }, []);

  return (
    <>
      <Breadcrumbs parent="Dashboard" title="Dashboard" />
      <Container fluid={true}>
        <Card>
          { isLoading && <CustomDataLoader />}
          <Row className="p-3">
            {
              currentAdmin?.user &&
              currentAdmin?.user.type == 'Employee' &&
              currentAdmin?.user?.employeeType === "Admin" && (
                <CompanyDashboard dashboardObj={dashboardObj} />
              )
            }
            {
              currentAdmin?.user &&
              currentAdmin?.user.type == 'Admin' && (
                <AdminDashboard dashboardObj={dashboardObj} />
              )
            }
            {
              currentAdmin?.user &&
              currentAdmin?.user.type == 'Employee' &&
              currentAdmin?.user?.employeeType !== "Admin" && (
                <EmployeeDashboard dashboardObj={dashboardObj} />
              )
            }
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default Dashboard;
