import { EMPLOYEE_ACTION_TYPES } from "./employee.types";

const EMPLOYEE_INITIAL_STATE = {
  employees: [],
  isLoading: false
};

export const employeeReducer = (state = EMPLOYEE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEE_ACTION_TYPES.FETCH_EMPLOYEES_START:
      return { ...state, isLoading: true}
    case EMPLOYEE_ACTION_TYPES.FETCH_EMPLOYEES_SUCCESS:
        return { ...state, employees: payload, isLoading: false }
    case EMPLOYEE_ACTION_TYPES.FETCH_EMPLOYEES_FAILED:
        return { ...state, error: payload, isLoading: false }
    default:
        return state;
  }
};