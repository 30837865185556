import { Button, Row, Spinner } from "reactstrap";
import photoPlaceholder from "../../assets/images/lightgallry/user-profile.jpg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomSearchBar from "../custom-search-bar/custom-search-bar.component";
import { fetchEmployeesAsync, loadMoreEmployeeDB } from "../../store/employees/employee.action";
import { updateManyEmployeeService } from "../../services/employee.services";
import { toast } from "react-toastify";
import SortIcon from "../custom-sort-icon/custom-sort-icon.component";
import { selectEmployees } from "../../store/employees/employee.selector";
import { useParams, useLocation  } from "react-router-dom";

const EmployeeList = ({ toggle, setEmployeeObj, isLoading, currentAdmin }) => {
  const { companyId, companyName } = useParams();
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState(null);
  const allEmployees = useSelector(selectEmployees);
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState();

  const location = useLocation();

  useEffect(() => {
    dispatch(fetchEmployeesAsync(1, '', {} , companyId));        
  }, [location]);


  const handleSort = (column) => {
    if (sortColumn === column) {
      // Toggle sort direction if same column clicked again
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new sort column and direction
      setSortColumn(column);
      setSortDirection('asc');
    }    

    column && dispatch(fetchEmployeesAsync(1, query, { [column]: sortDirection ? sortDirection : 'asc'  }, companyId));
  };

  const handleRowSelect = (event, id) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    }
  };

  const loadMoreEmployee = () => {
    dispatch(
      loadMoreEmployeeDB(allEmployees?.meta?.current_page + 1, allEmployees, query, companyId)
    );
  };

  const searchEmployee = () => {
    dispatch(fetchEmployeesAsync(1, query, {}, companyId));
  };

  const updateManyEmployees = async (status) => {
    setStatus(status);
    setIsUpdating(true);
    const { data: { data } } = await updateManyEmployeeService(selectedRows, status);
    if ( data.length > 0 ) {
      for (let i = 0; i < data.length; i++) {
        let index = allEmployees.data.findIndex(
          (emp) => emp.id === data[i].id
        );

        allEmployees.data[index] = data[i];
      }
    }
    toast.success('Users status updated successfully!');
    setIsUpdating(false);
  }
  return (
    <Row>
      {
        selectedRows.length > 0 &&
        (currentAdmin?.user?.type ==='Admin' && currentAdmin.user.role === 'Super Admin') && <div className="text-end d-flex flex-row-reverse gap-1">
          <button className="btn btn-primary" onClick={() => updateManyEmployees(1)} disabled={isUpdating}> { isUpdating && status === 1 ? <Spinner /> : "Active" }</button>
          <button className="btn btn-secondary" onClick={() => updateManyEmployees(0)} disabled={isUpdating}>{ isUpdating && status === 0 ? <Spinner /> : "Deactive" }</button>
        </div> 
      }
      <CustomSearchBar
        placeholder="Search here..."
        setQuery={setQuery}
        keyUpFunc={searchEmployee}
      />
      { allEmployees?.data?.length > 0 && <InfiniteScroll
        dataLength={allEmployees?.data?.length}
        next={loadMoreEmployee}
        hasMore={allEmployees?.meta?.current_page < allEmployees?.meta?.last_page}
        loader={<div className="primary-color text-center"> loading... </div>}
        scrollableTarget="scrollableEmployeeDiv"
        endMessage={
          <p className="text-center primary-color">
            {!isLoading && <b> You have seen it all</b>}
          </p>
        }
      >
        <table className="table align-baseline">
          <thead>
            <tr>
              <th>#</th>
              {(currentAdmin?.user?.type ==='Admin' && currentAdmin?.user.role.includes('Admin')) && <th></th>}
              <th>Profile</th>
              <th  onClick={() => handleSort('name')} >{sortColumn === 'name' ? <SortIcon direction={sortDirection} />: '️↕️'}Name</th>
              <th >Email</th>
              { currentAdmin?.user?.type ==='Admin' && <th onClick={() => handleSort('company.name')} > {sortColumn === 'company.name' ? <SortIcon direction={sortDirection} />: '️↕️'}Company </th> }
              <th  onClick={() => handleSort('employee_type')} >{sortColumn === 'employee_type' ? <SortIcon direction={sortDirection} />: '️↕️'}Role </th>
              <th>Created At</th>
              {currentAdmin?.user?.type !== 'Admin' && <th>Updated At</th>}
              {currentAdmin?.user?.type ==='Admin' && <th>Created By</th>}
              <th onClick={() => handleSort('status')} >{sortColumn === 'status' ? <SortIcon direction={sortDirection} />: '️↕️'}Status </th>
              {((currentAdmin?.user.type === 'Admin' && currentAdmin?.user.role.includes('Admin')) || 
                    currentAdmin?.user.type === 'Employee') && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {!isLoading && allEmployees?.data?.map((employee, index) => (
              <tr key={ employee.id }>
                <td>{ index + 1 }</td>
                {(currentAdmin?.user?.type ==='Admin' && currentAdmin?.user.role.includes('Admin')) && <td>
                  <input type="checkbox" checked={selectedRows.includes(employee.id)} onChange={(event) => handleRowSelect(event, employee.id)} />
                </td>}
                <td>
                  <img
                    src={
                      employee.profileImage
                        ? employee.profileImage
                        : photoPlaceholder
                    }
                    className="user-profile"
                  />
                </td>
                <td>{employee.name}</td>
                <td style={{ maxWidth: "15%", overflow: "hidden" }}>{employee.email}</td>
                { currentAdmin?.user?.type ==='Admin' && <td>{employee.company?.name}</td>}
                <td>{employee.employeeType}</td>
                <td>{employee.createdAt}</td>
                {currentAdmin?.user?.type ==='Admin' && <td>{employee.createdBy?.email}</td>}
                {currentAdmin?.user?.type !=='Admin' && <td>{employee.updatedAt}</td>}
                <td>{employee.status == 1 ? "Active" : "Deactive"}</td>
                <td>
                  <div className="d-flex gap-1">
                    {
                    ((currentAdmin?.user.type === 'Admin' && currentAdmin?.user.role.includes('Admin')) || 
                    (currentAdmin?.user.type === 'Employee' && employee.employeeType != 'Admin')) && (currentAdmin?.user.id != employee.id) &&  <Button
                      color="primary"
                      outline={true}
                      onClick={() => {
                        console.log("Employee", employee);
                        setEmployeeObj(employee);
                        toggle();
                      }}
                    >
                      Edit
                    </Button> }
                  </div>
                </td>
              </tr>
            ))}

            
          </tbody>
        </table>
      </InfiniteScroll>}

      {isLoading && (
        <div className="text-center">
            <Spinner />
        </div>
        )}

        {!isLoading && allEmployees?.data?.length === 0 && (
            <div className="text-center">
              <p>{currentAdmin?.user?.type !=='Admin' ? "Employee" : 'User'} list is empty.</p>
            </div>
        )}
    </Row>
  );
};

export default EmployeeList;
