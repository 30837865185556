import { useEffect, useState } from "react";
import Select from "react-select";
import photoPlaceholder from "../../../assets/images/lightgallry/user-profile.jpg";
import { useDispatch } from "react-redux";
import { fetchEmployeesAsync } from "../../../store/employees/employee.action";


const ShareDocumentEmployee = ({ allEmployee, setSelectedEmployeeToShare }) => {
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if(allEmployee.data && allEmployee.data.length == 0) dispatch(fetchEmployeesAsync());        
  }, []);


  return (
    <Select
      options={allEmployee.data.map((employee) => ({
        value: employee.id,
        label: employee.name,
        image: employee.profileImage ? employee.profileImage : photoPlaceholder,
      }))}
      value={selectedEmployees}
      onChange={(data) => 
        {
          setSelectedEmployeeToShare(data);
          return setSelectedEmployees(data)
        }}
      isSearchable={true}
      isMulti
      formatOptionLabel={(emploee) => (
        <div className="emploee-option d-flex gap-1 align-items-center">
          <img
            src={emploee.image}
            alt="emploee-image"
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          />
          <span>{emploee.label}</span>
        </div>
      )}

      placeholder="select at least one employee to share."
    />
  );
};

export default ShareDocumentEmployee;
