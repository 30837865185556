import photoPlaceholder from "../../../assets/images/lightgallry/01.jpg";

import { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { deleteDocumentEmployeeService } from "../../../services/employee-documents.services";

const DocumentAssigneeDropdown = ({ documentAssignees, getDocs }) => {
  const [dropdownOpen, setOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const handleDeleteEmployee = (e, employeeId) => {
    e.stopPropagation()
    // setIsLoading(true)
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteDocumentEmployeeService(documentAssignees.id, employeeId).then(
          () => {
            SweetAlert.fire(
              "Deleted!",
              "Your Employee from Document has been deleted.",
              "success"
            );
            getDocs()
          },
          (err) => {
            SweetAlert.fire(
              "Not deleted",
              "Your employee from document has not been deleted.",
              "error"
            );
          }
        );
      } else {
        SweetAlert.fire("Your employee is safe!");
      }
    });
  }
  return (
    <Dropdown
      toggle={() => {
        setOpen(!dropdownOpen);
      }}
      isOpen={dropdownOpen}
    >
      <DropdownToggle color="white" className="d-flex gap-1">
        {documentAssignees.employees.slice(0, 3).map((employee) => (
          <img
            key={employee.id}
            src={
              employee.profile_image ? employee.profile_image : photoPlaceholder
            }
            alt={employee.name}
            title={employee.name}
            className="avatar"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "20px",
              position: "relative",
            }}
          />
        ))}

        {
          documentAssignees.employees.length > 3 && <div style={{
            width: "30px",
            height: "30px",
            borderRadius: "20px",
            border: "1px solid gray",
            position: "relative",
            display:"flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "10px"
          }}>More</div>
        }
      </DropdownToggle>
      <DropdownMenu style={{ minWidth: "fit-content" }}>
        {documentAssignees.employees.map((assignee, index) => (
          <div key={index}>
            <div className="d-flex justify-content-between gap-2 align-items-center" style={{ padding: "10px", minWidth:"max-content" }}>
              <div className="d-flex align-items-center gap-1" >
                <img
                  src={
                    assignee.profile_image
                      ? assignee.profile_image
                      : photoPlaceholder
                  }
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "20px",
                    position: "relative",
                  }}
                  alt={assignee.name}
                  className="avatar"
                />
                <span>{assignee.name}</span>
              </div>
              <div  onClick={(e) => handleDeleteEmployee(e, assignee.id) }>
                <Button size="sm" color="secondary">
                  Delete
                </Button>
              </div>
            </div>
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DocumentAssigneeDropdown;
