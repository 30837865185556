import React, { useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./admin.styles.scss";
import { useState } from "react";
import CustomModal from "../custom-modal/custom-modal.component";
import { fetchAdminsAsync } from "../../store/admin/admin.action";
import { selectAdmins, selectAdminsIsLoading, selectCurrentAdmin } from "../../store/admin/admin.selector";
import AdminForm from "./admin-form/admin-form.component";
import AdminList from "./admin-list/admin-list.component";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
// import { getTotalAdmin } from "../../services/auth-firebase.services";
import { Plus } from "react-feather";

const Admin = () => {
  const [modal, setModal] = useState(false);
  const [totalCountAdmin, setTotalCountAdmin] = useState(0);
  const toggle = () => setModal(!modal);

  const dispatch = useDispatch();

  const isLoading = useSelector(selectAdminsIsLoading);
  const allAdmins = useSelector(selectAdmins);

  const [adminObj, setAdminObj] = useState({});
  const setAdminFormData = (packObj) => setAdminObj(packObj);

  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    dispatch(fetchAdminsAsync());
  }, []);

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Admin" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
                 <h5 className="d-flex gap-1"> <CustomBackButton /> List Of Admin</h5>
                 {currentAdmin?.user.role.includes('Admin') && <div className="add-new-employee">
                    <Button color='primary' outline={true} className='d-flex gap-1 ' onClick={() => {
                      setAdminFormData({});
                      toggle();
                    }}> <Plus/> Add New Admin</Button>
                 </div> }
              </CardHeader>
              <CardBody className="main-content-card-body" id="scrollableDiv">
                <Row>
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                    <AdminList toggle={toggle} isLoading={isLoading} allAdmins={allAdmins} setAdminFormData={setAdminFormData}/> 
                      <CustomModal
                        toggle={toggle}
                        modal={modal}
                        modalTitle={"Save Admin"}
                      >
                        <AdminForm toggle={toggle} adminObj={adminObj} />
                      </CustomModal>
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Admin;
