import React, { useEffect, useRef, useState } from "react";
import "./custom-editor.styles.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { API_BASE_URL } from "../../../constant/constant";
const CustomEditor = ({ onChange, editorLoaded, name, value, readonly }) => {
  let editorRef = useRef();

  const [state, setState] = useState({
    data: value || "",
    editor: null,
  });

  
  useEffect(() => {
    const editor = (
      <CKEditor
        type=""
        name={name}
        onReady={(editor) => {
          console.log("Editor is ready to use!", editor);

          // Insert the toolbar before the editable area.
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );

          editorRef = editor;
        }}
        onError={(error, { willEditorRestart }) => {
          if (willEditorRestart) {
            editorRef.ui.view.toolbar.element.remove();
          }
        }}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        editor={DecoupledEditor}
        config={
          {
          toolbar: [
            "heading",
            'alignment',
            '|', 
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "|",
            "outdent",
            "indent",
            
            // "ckfinder",

            "|",
            "imageTextAlternative",
            "imageUpload",
            "imageStyle:full",
            "imageStyle:side",
            "|",
            // 'mediaEmbed',
            "insertTable",
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "|",
            "undo",
            "redo",
          ],
          ckfinder: {
            // Upload the images to the server using the CKFinder QuickUpload command
            // You have to change this address to your server that has the ckfinder php connector
            uploadUrl: API_BASE_URL + "/file-upload", //Enter your upload url
          },
          isReadOnly: readonly,
        }}
      />
    );
    setState({ ...state, editor: editor });
  }, [value]);
  return <div>{editorLoaded ? state.editor : <div>Editor loading</div>}</div>;
};

export default CustomEditor;
