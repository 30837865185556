import "./company-card.styles.scss";

import photoPlaceholder from "../../../assets/images/lightgallry/01.jpg";
// import { FIREBASE_STORAGE_BASE_URL } from "../../../constant/constant";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { Users } from "react-feather";
import { Link } from 'react-router-dom';

const CompanyCard = ({ company, setCompanyFormData, toggle }) => {
  const { email, name, logo, address, id, createdAt, updatedAt } = company;
  const currentAdmin = useSelector(selectCurrentAdmin);
  const handleOpenCompanyFormModal = () => {
    if(currentAdmin?.user.role === 'Editor' || currentAdmin?.user.role.includes('Admin')) {
      setCompanyFormData(company);
      toggle();
    }
  };
  return (
    <>
      <div className="col-4 mb-3">
        <div
          className="tag-content h-100 position-relative main-list-hover"
          onClick={handleOpenCompanyFormModal}
        >
          <div className="tag-box position-relative">
            <div className="tag-img">
              <img
                src={
                  logo ? '' + logo : photoPlaceholder
                }
                alt={name}
              />
            </div>
            <div className="text-container-box mt-2" >
              <span className="tag-title mt-10"><strong>Company Name</strong>: {name}</span>
              <p className="tag-title mt-10"><strong>Company Address</strong>:{ address }  </p>
              <p className="tag-title mt-10"><strong>Created At</strong>:{ createdAt }  </p>
              <p className="tag-title mt-10"><strong>Updated At</strong>:{ updatedAt }  </p>

            </div>

            
          </div>

          {currentAdmin?.user.role.includes('Admin') && <div className="p-3 text-end">
            <Link to={`/companies/${id}/${name.replace(new RegExp("[;\\\\/:*?$#\"<>|&']"), ' ')}/employee`} onClick={(event) => event.stopPropagation()}> <Users /> Employees </Link>
          </div>}
        </div>
      </div>
    </>
  );
};

export default CompanyCard;
