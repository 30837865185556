import { SkipBack } from "react-feather"
import { useNavigate } from "react-router";
const CustomBackButton = () => {
    const navigate = useNavigate();
    return (
        <button className="bg-transparent border-0" type="button" title="Back to Previous Page" onClick={() => navigate(-1)}>
            <SkipBack className="primary-color" style={{ cursor: "pointer" }}   />
        </button>
    )
}

export default CustomBackButton;