import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import {
  addCompany,
  deleteCompany,
  updateCompany,
} from "../../../store/companies/company.action";
import { selectCompanies } from "../../../store/companies/company.selector";
import { showServerErrorMessage } from "../../../utils/show-server-error-message";

const CompanyForm = ({ toggle, companyObj }) => {
  const [values, setValues] = useState({
    name: "",
    logo: null,
    address: "",
    registrationNo: "",
    natureOfBusiness: "",
    telephoneNo: "",
    faxNo: "",
    viceChairman: "",
    website: "",
    chairman: "",
    status: 1,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const allCompanies = useSelector(selectCompanies);
  const currentAdmin = useSelector(selectCurrentAdmin);
  //state for steps
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (Object.keys(companyObj).length > 0) {
      const { id, ...rest } = companyObj;
      setValues({ ...values, ...rest });
      setCompanyId(id);
      setPhotoUrl(companyObj.logo ? companyObj.logo : null);
    }
  }, [companyObj]);

  const handleSubmit = (e) => {
    console.log("photoUrl", photoUrl);
    e.preventDefault();
    if (isUploading) return;
    setIsSaving(true);
    values.logo = photoUrl ? photoUrl : null;

    companyId
      ? updateCompany(
          { ...values, id: companyId },
          allCompanies.data,
          companyId
        ).then((res) => {
          if (res.status == 200 || res.status == 201) {
            toast.success("Your company has been saved.");
            toggle();
          } else {
            showServerErrorMessage(res);
          }
          toggle();
          setIsSaving(false);
        })
      : addCompany(values, allCompanies.data).then((res) => {
          if (res.status == 200 || res.status == 201) {
            toast.success("Your company has been saved.");
            toggle();
          } else {
            showServerErrorMessage(res.response);
          }
          toggle();
          setIsSaving(false);
        });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Handle Delete Company
  const handleDeleteCompany = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteCompany(companyId).then(
          () => {
            SweetAlert.fire(
              "Deleted!",
              "Your Company has been deleted.",
              "success"
            );
            const index = allCompanies.data.findIndex(
              (alb) => alb.id === companyId
            );
            allCompanies.data.splice(index, 1);
            toggle();
          },
          (err) => {
            SweetAlert.fire(
              "Not deleted",
              "Your Company has not been deleted.",
              "error"
            );
          }
        );
      } else {
        SweetAlert.fire("Your company is safe!");
      }
    });
  };

  return (
    <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
      <div className="form-row row">
        <div className="col-8">
          <div className="mb-3">
            <FormInput
              name="name"
              type="text"
              placeholder="Company name"
              errorMessage="Company name should be more than 3 characters!"
              label="name"
              min={3}
              required
              value={values["name"]}
              onChange={onChange}
            />
          </div>

          <div className="mb-3">
            <FormInput
              name="address"
              type="textarea"
              placeholder="Company Address"
              errorMessage="Company address should be more than 3 characters!"
              label="address"
              min={3}
              required
              value={values["address"]}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="col-4">
          <FormInput
            name="logo"
            type="file"
            placeholder="Upload a logo"
            label="Company logo"
            uploadDirectory="Company"
            value={values["logo"]}
            setValues={setValues}
            values={values}
            setIsUploading={setIsUploading}
            setPhotoUrl={setPhotoUrl}
            photoUrl={photoUrl}
            onChange={onChange}
          />
        </div>
        <div className="col-6 mb-3">
          <FormInput
            name="chairman"
            type="text"
            placeholder="Company chairman"
            label="chairman"
            value={values["chairman"]}
            onChange={onChange}
          />
        </div>
        <div className="col-6 mb-3">
          <FormInput
            name="viceChairman"
            type="text"
            placeholder="Company vice chairman"
            label="vice chairman"
            value={values["viceChairman"]}
            onChange={onChange}
          />
        </div>

        <div className="col-6 mb-3">
          <FormInput
            name="telephoneNo"
            type="text"
            placeholder="Company telephone no"
            label="telephone no"
            value={values["telephoneNo"]}
            onChange={onChange}
          />
        </div>

        <div className="col-6 mb-3">
          <FormInput
            name="website"
            type="text"
            placeholder="Company website url"
            label="website"
            value={values["website"]}
            onChange={onChange}
          />
        </div>

        <div className="col-4 mb-3">
          <FormInput
            name="registrationNo"
            type="text"
            placeholder="Company registration no"
            label="registration no"
            value={values["registrationNo"]}
            onChange={onChange}
          />
        </div>
        <div className="col-4 mb-3">
          <FormInput
            name="natureOfBusiness"
            type="text"
            placeholder="Company nature of business "
            label="nature of business"
            value={values["natureOfBusiness"]}
            onChange={onChange}
          />
        </div>
        <div className="col-4 mb-3">
          <FormInput
            name="faxNo"
            type="text"
            placeholder="Company fax no "
            label="fax no"
            value={values["faxNo"]}
            onChange={onChange}
          />
        </div>
        <div
          className={
            companyId && currentAdmin.user?.role === 'Super Admin'
              ? "d-flex justify-content-between"
              : ""
          }
        >
          {companyId && currentAdmin.user?.role === 'Super Admin' && (
            <Button color="secondary" onClick={handleDeleteCompany}>
              Delete
            </Button>
          )}
          <div
            className={
              !companyId || !currentAdmin?.user.role.includes('Super Admin')
                ? "d-flex justify-content-between"
                : "d-flex gap-2"
            }
          >
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>

            <div className="d-flex gap-1">
              <Button
                color="primary"
                className="mr-1"
                disabled={isSaving ? true : false}
              >
                {isSaving ? (
                  <Spinner style={{ height: "20px", width: "20px" }} />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default CompanyForm;
