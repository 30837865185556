import { toast } from "react-toastify";

export const showServerErrorMessage = (response) => {
    const errors = response.data.errors;
    
    if (response.data.message) {
        toast.error(response.data.message);
    }
    for (const error in errors) { 
        if (errors[error].length > 0) {
            for (const err of errors[error]) {
                toast.error(err);
            }
        }
    }

}