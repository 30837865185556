import {Home,Users, UserCheck, Compass, File} from 'react-feather'
export const COMPANY_MENUITEMS = [
    {
        Items:[
            { path: `dashboard`,icon:Home, title: 'Dashboard', type: 'link',  active: false },
        
        ]
    },
    {
        Items:[
            { path: `employees`,icon:Users, title: 'Employees', type: 'link',  active: false },
        
        ]
      },
      {
        Items:[
            {
                title: 'Templates', icon: File, type: 'sub', active: false, children: [
                    { path: `/doc-templates`, title: 'Our Docs Template', type: 'link', active: false },
                    { path: `/doc-admin-templates`, title: 'Admin Docs Template', type: 'link', active: false },
                ]
            }, 
            
        ]
       
      }
      , {
        Items:[
            { path: `emp-docs`,icon: File, title: 'Employee Documents', type: 'link',  active: false },
        
        ]
      },
   
    
    
       
]