import { DASHBOARD_ACTION_TYPES } from "./dashboard.types";

const DASHBOARD_INITIAL_STATE = {
  dashboard: {},
  isLoading: false,
  error: null
};

export const dashboardReducer = (state = DASHBOARD_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_START:
      return { ...state, isLoading: true}
    case DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_SUCCESS:
        return { ...state, dashboard: payload, isLoading: false }
    case DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_FAILED:
        return { ...state, error: payload, isLoading: false }
    default:
        return state;
  }
};