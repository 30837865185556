import { Navigate, useOutlet } from "react-router";
import { Link } from "react-router-dom";
import "./authentication.style.scss";
import { ToastContainer } from "react-toastify";

const Authentication = () => {
  const outlet = useOutlet();
  return (
    <div className="authentication-container">
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="login-card">
              <div>
                <div className="text-center">
                  <Link to={"/authentication/login"} className="logo">
                    <img
                      className="img-fluid for-light bakhtar-logo"
                      src={require("../../assets/images/logo/logo.png")}
                      alt="looginpage"
                    />
                    <img
                      className="img-fluid for-dark bakhtar-logo"
                      src={require("../../assets/images/logo/logo_dark.png")}
                      alt="looginpage"
                    />
                  </Link>
                </div>
                <div className="login-main">
                  {outlet || <Navigate replace to="/authentication/login" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {/* <SignUpForm /> */}
    </div>
  );
};

export default Authentication;
