import './custom-data-loader.styles.scss';

const CustomDataLoader = () => {
    return (
        <div className="data-loader">
            <div className="loader"></div>
        </div>
    )
}

export default CustomDataLoader;