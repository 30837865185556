import { createSelector } from "reselect";

export const selectCurrentAdmin = (state) => state.admin.currentAdmin;

export const selectLastAdminDoc = (state) => state.admin.lastDoc;

const selectAdminReducer = (state) => {
    return state.admin;
};

export const selectAdmins = createSelector(
    [selectAdminReducer],
    (adminSlice) => adminSlice.admins
)

export const selectAdminsIsLoading = createSelector(
    [selectAdminReducer],
    (adminsSlice) => adminsSlice.isLoading
)