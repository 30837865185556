import { Plus } from "react-feather";
import "./employee.styles.scss";
import Breadcrumbs from "../../layout/breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import CustomBackButton from "../custom-back-button/custom-back-button.component";

import EmployeeForm from "./employee-form/employee-form.component";
import CustomModal from "../custom-modal/custom-modal.component";
import { useEffect, useState } from "react";
import {
  selectEmployees,
  selectEmployeesIsLoading,
} from "../../store/employees/employee.selector";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEmployee,
  fetchEmployeesAsync,
} from "../../store/employees/employee.action";
import EmployeeList from "./employee-list.component";
import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import { useParams } from "react-router-dom";

const Employee = () => {
  const { companyId, companyName } = useParams();
  const [modal, setModal] = useState(false);
  
  const isLoading = useSelector(selectEmployeesIsLoading);
  
  const [employeeObj, setEmployeeObj] = useState({});
  
  const currentAdmin = useSelector(selectCurrentAdmin);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Breadcrumbs parent="Dashboard" title="Employee" />
      <Container fluid={true}>
        <CustomModal
          toggle={toggle}
          modal={modal}
          modalTitle={"Create | Edit Emplyee"}
        >
          <EmployeeForm toggle={toggle} employeeObj={employeeObj} />
        </CustomModal>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
                <h5 className="d-flex gap-1 m-0 p-0">
                  <CustomBackButton /> List Of {companyName ? companyName : ''} Employees 
                </h5>
                {(currentAdmin?.user?.type !== 'Admin' || companyId) && <div className="add-new-employee">
                  <Button
                    color="primary"
                    outline={true}
                    className="d-flex gap-1 "
                    onClick={() => {
                      setEmployeeObj({});
                      toggle();
                    }}
                  >
                    <Plus /> Add New Employee
                  </Button>
                </div>}
              </CardHeader>
              <CardBody style={{ height: "80vh", overflowY: "auto"}} id="scrollableEmployeeDiv">
                  <EmployeeList toggle={toggle} isLoading={isLoading} setEmployeeObj={setEmployeeObj} currentAdmin={currentAdmin} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Employee;
