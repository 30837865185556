import React, { Fragment } from 'react';
// import Loader from "../layout/loader";
import Header from '../header'
import Sidebar from '../sidebar'
import { Navigate, useOutlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {ToastContainer} from 'react-toastify'

const Main = () => {
  const outlet = useOutlet();
  return (
    <Fragment>
      {/* <Loader/> */}
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Header/>
      <div className="page-body-wrapper sidebar-icon">
        <Sidebar/>
        <div className="page-body">
          { outlet || <Navigate replace to="/dashboard" />}
        </div>
        {/* <Footer/> */}
        </div>
      </div>
      {/* <ToastContainer/> */}
      <ToastContainer />
    </Fragment>
  );
}

export default Main;