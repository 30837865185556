import React from "react";
import { Route, Navigate } from "react-router-dom";
import Main from "./layout/main";
import Authentication from "./layout/authentication/authentication.component";
import { useEffect } from "react";
import { useState } from "react";
import Dashboard from "./components/dashboard/dashboard.component";
import CustomDataLoader from "./components/custom-data-loader/custom-data-loader.component";
import CustomRoutesContainer from "./components/custom-routes-container/custom-routes-container.component";
import SignInForm from "./components/auth/sign-in-form/sign-in-form.component";
import { adminRoutes } from "./routes/admin-route.component";
import { employeeRoutes } from "./routes/employee-route.component";
import { companyRoutes } from "./routes/company-route.component";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "./store/admin/admin.selector";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const currentAdmin = useSelector(selectCurrentAdmin)?.user;

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading && <CustomDataLoader />}
      <CustomRoutesContainer>
        {(currentAdmin &&
        Object.keys(currentAdmin).length > 0) && (
          <Route path="/" element={<Main />}>
            <Route index={true} path="dashboard" element={<Dashboard />} />

            {currentAdmin &&
              currentAdmin.type === "Admin" &&
              adminRoutes.map(({ path, Component }, index) => (
                <Route
                  key={path + index}
                  path={`${path}`}
                  element={<Component />}
                />
              ))}

            {currentAdmin &&
              currentAdmin.type === "Employee" &&
              currentAdmin.employeeType !== "Admin" &&
              employeeRoutes.map(({ path, Component }, index) => (
                <Route
                  key={path + index}
                  path={`${path}`}
                  element={<Component />}
                />
              ))}

            {currentAdmin &&
              currentAdmin.type === "Employee" &&
              currentAdmin &&
              currentAdmin.employeeType === "Admin" &&
              companyRoutes.map(({ path, Component }, index) => (
                <Route
                  key={path + index}
                  path={`${path}`}
                  element={<Component />}
                />
              ))}

            <Route path="*" element={ <Navigate replace to="/dashboard" /> } />
          </Route>
        ) }
        <Route path="/authentication" element={<Authentication />}>
          <Route path="login" element={<SignInForm />} />
          {/* <Route path="signup" element={<SignUpForm />} />
          <Route path="forgotPassword" element={<ForgotPasswordForm />} />
          <Route path="resetPassword" element={<SignUpForm />} /> */}
        </Route>
        <Route
          path="*"
          element={<Navigate replace to="/authentication/login" />}
        />
      </CustomRoutesContainer>
    </>
  );
};

export default App;
