import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import { PolarArea } from 'react-chartjs-2';
import { Col } from 'reactstrap';
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);


const CompanyDashboard = ({dashboardObj}) => {
    const polarData = {
        labels: ['Admin Employee', 'Other Employee'],
        datasets: [
          {
            label: 'Total ',
            data: [
              dashboardObj?.totalEmployees?.find(({employee_type}) => employee_type == 'Admin' )?.total, 
              dashboardObj?.totalEmployees?.find(({employee_type}) => employee_type !== "Admin" )?.total
            ],
            backgroundColor: [
              'rgba(75, 192, 192, 0.5)',
              'rgba(255, 159, 64, 0.5)',
            ],
            borderWidth: 1,
          },
        ],
    };
  
    const pieData = {
        labels: ['Employee Open Docs', 'Employee Pending Docs', 'Employee Approved Docs'],
        datasets: [
          {
            label: 'Total',
            data: [
              dashboardObj?.totalEmployeeDocs?.find(({status}) => status == 1 )?.total, 
              dashboardObj?.totalEmployeeDocs?.find(({status}) => status == 2 )?.total,
              dashboardObj?.totalEmployeeDocs?.find(({status}) => status == 3 )?.total
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',  
            ],
            borderWidth: 1,
          },
        ],
    };
  
    const data = {
        labels: ['Our Document Templates', 'Admin Document Templates'],
        datasets: [
          {
            label: 'Total',
            data: [
              dashboardObj?.totalOurDocument, 
              dashboardObj?.totalAdminDocument, 
            ],
            backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
              'rgba(153, 102, 255, 0.2)',
            ],
            borderColor: [
            'rgba(255, 159, 64, 1)',
              'rgba(153, 102, 255, 1)',
            ],
            borderWidth: 1,
          },
        ],
    };
    return (
        <>
            <Col sm="6" className="mb-4" >
                <Doughnut data={data} />
            </Col>
            <Col sm="6" className="mb-4" >
                <Pie data={pieData} />
            </Col>
            <Col sm="6" className="mb-4 offset-sm-3">
                <PolarArea data={polarData} />
            </Col>
        </>
    )
}

export default CompanyDashboard;