import { API_BASE_URL } from "../constant/constant";
import axios from "axios";

export const loginUser = async (data) => {
    const user = await axios.post(API_BASE_URL + '/login', data);

    return user;
}

export const logoutUser = async () => {
    const user = await axios.post(API_BASE_URL + '/logout');
    return user;
}