import { createSelector } from "reselect";

const selectCompanyReducer = (state) => state.companies;

export const selectCompanies = createSelector(
    [selectCompanyReducer],
    (companySlice) => {
        return companySlice.companies;
    }
)

export const selectCompaniesIsLoading = createSelector(
    [selectCompanyReducer],
    (companySlice) => companySlice.isLoading
)