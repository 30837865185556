import { combineReducers } from "redux";
import { adminReducer } from "./admin/admin.reducer";
import { dashboardReducer } from "./dashboard/dashboard.reducer";
import { employeeReducer } from "./employees/employee.reducer";
import { companyReducer } from "./companies/company.reducer";

export const rootReducer = combineReducers({
    admin: adminReducer,
    dashboard: dashboardReducer,
    employees: employeeReducer,
    companies: companyReducer,
})