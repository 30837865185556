import { ADMIN_ACTION_TYPES } from "./admin.types";

const INITIAL_STATE = {
  currentAdmin: localStorage.getItem("user-data") ? JSON.parse(localStorage.getItem("user-data")): null,
  admins: {
    data: [],
    links: {},
    meta: {},
  },
  lastDoc: null
};

export const adminReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_ACTION_TYPES.SET_CURRENT_ADMIN:
      return { ...state, currentAdmin: payload };
    case ADMIN_ACTION_TYPES.FETCH_ADMINS_START:
      return { ...state, isLoading: true };
    case ADMIN_ACTION_TYPES.FETCH_ADMINS_SUCCESS:
      return { ...state, admins: payload, isLoading: false };
    case ADMIN_ACTION_TYPES.FETCH_ADMINS_FAILED:
      return { ...state, error: payload, isLoading: false };
    case ADMIN_ACTION_TYPES.SET_LAST_ADMIN_DOC:
      return { ...state, lastDoc: payload, isLoading: false };
    default:
      return state;
  }
};
