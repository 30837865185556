import React, { useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./companies.styles.scss";
import { useState } from "react";
import CustomModal from "../custom-modal/custom-modal.component";
import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import CustomSearchBar from "../custom-search-bar/custom-search-bar.component";
import CompanyCard from "./company-card/company-card.component";
import CompanyForm from "./company-form/company-form.component";
import { Plus } from "react-feather";
import { selectCompanies, selectCompaniesIsLoading } from "../../store/companies/company.selector";
import { fetchCompaniesAsync, loadMoreCompanies } from "../../store/companies/company.action";
import InfiniteScroll from 'react-infinite-scroll-component';

const Companies = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const dispatch = useDispatch();

  const isLoading = useSelector(selectCompaniesIsLoading);
  const allCompanies = useSelector(selectCompanies);


  const [companyObj, setCompanyObj] = useState({});
  const setCompanyFormData = (empObj) => setCompanyObj(empObj);

  const currentAdmin = useSelector(selectCurrentAdmin);

  const [query, setQuery] = useState("")

  useEffect(() => {
    dispatch(fetchCompaniesAsync());
  }, []);


  const loadMoreCompany = () => {
    if (allCompanies.meta.current_page < allCompanies.meta.last_page) {
      dispatch(loadMoreCompanies(allCompanies.meta.current_page + 1, allCompanies))
    }
  }
  return (
    <>
      <Breadcrumb parent="Dashboard" title="Company" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent  d-flex justify-content-between">
                <h5 className="d-flex gap-1"> <CustomBackButton /> List Of Companies </h5>
                {currentAdmin?.user.role.includes('Admin') && <div className="add-new-employee">
                  <Button color='primary' outline={true} className='d-flex gap-1 ' onClick={() => {
                    setCompanyObj({});
                    toggle();
                  }}> <Plus/> Add New Company</Button>
                 </div>}
              </CardHeader>
              <CardBody className="main-content-card-body">
                <Row>
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <CustomSearchBar placeholder="Search your company name here..." setQuery={setQuery} />

                      <CustomModal
                        toggle={toggle}
                        modal={modal}
                        modalTitle={"Save Company"}
                      >
                        <CompanyForm toggle={toggle} companyObj={companyObj} />
                      </CustomModal>

                      { allCompanies?.meta?.total && <InfiniteScroll
                      dataLength={ allCompanies.data.length }
                      next={loadMoreCompany}
                      hasMore={ allCompanies.meta.current_page < allCompanies.meta.last_page }
                      loader={<div className='primary-color text-center'> loading... </div>}
                      scrollableTarget="scrollableDiv"
                      endMessage={
                        <p className='text-center primary-color'>
                          <b>You have seen it all</b>
                        </p>
                      }
                    >
                      <Row>
                        {
                          allCompanies?.data?.filter(company => {
                          if (query === '') {
                            return company;
                          } else if (company.name.toLowerCase().includes(query.toLowerCase())) {
                            return company;
                          }
                        }).map((company) => (
                            <CompanyCard
                              key={company.id}
                              company={company}
                              setCompanyFormData={setCompanyFormData}
                              toggle={toggle}
                            />
                          ))}
                      </Row>
                      </InfiniteScroll> }
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Companies;
