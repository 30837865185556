import { createAction } from "../../utils/reducer.utils";
import { DASHBOARD_ACTION_TYPES } from "./dashboard.types";

export const fetchTotalColAlbVidAudStart = () =>
    createAction(DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_START);


export const fetchTotalColAlbVidAudSuccess = (dashboardObj) =>
    createAction(DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_SUCCESS, dashboardObj);


export const fetchTotalColAlbVidAudFailed = (error) =>
    createAction(DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_FAILED, error);

export const fetchTotalColAlbVidAudAsync = () => async (dispatch) => {
    dispatch(fetchTotalColAlbVidAudStart());

    try {
        // const dashboardObj = await getTotalCollectionAlbumVideoAudio();
        // dispatch(fetchTotalColAlbVidAudSuccess(dashboardObj));
    } catch(error) {
        dispatch(fetchTotalColAlbVidAudFailed(error))
    }
}
