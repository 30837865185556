import {Home, File} from 'react-feather'
export const EMPLOYEE_MENUITEMS = [
    {
        Items:[
            { path: `dashboard`, icon:Home, title: 'Dashboard', type: 'link',  active: false },
        
        ]
    },

    {
        Items:[
            {
                title: 'Documents', icon: File, type: 'sub', active: false, children: [
                    { path: `/emp-open-docs`, title: 'Open Documents', type: 'link', active: false },
                    { path: `/emp-not-open-docs`, title: 'Reviewed | Pending Documents', type: 'link', active: false },
                ]
            }
        ]
       
      }   
]