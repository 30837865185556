import { Spinner, Form, Button } from "reactstrap";
// import "./document.styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { fetchTotalColAlbVidAudAsync } from "../../../../store/dashboard/dashboard.action";
import { selectCurrentAdmin } from "../../../../store/admin/admin.selector";
import CustomEditor from "../../custom-editor/custom-editor.component";
import FormInput from "../../../form-input/form-input.component";
import {
  createDocumentService,
  updateDocumentService,
} from "../../../../services/documents.services";
import { updateEmployeeDocumentService } from "../../../../services/employee-documents.services";
import { toast } from "react-toastify";
import CustomDataLoader from "../../../custom-data-loader/custom-data-loader.component";

const DocumentTemplateForm = ({ toggle, allDocuments, documentObj, id }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTotalColAlbVidAudAsync());
  }, []);

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState("");
  const [value, setValue] = useState("");
  const [docId, setDocID] = useState(id ? id : null);
  const [status, setStatus] = useState(1);

  const [values, setValues] = useState({
    title: "",
    body: "",
  });

  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    if (documentObj && Object.keys(documentObj).length > 0) {
      setValues({
          title: documentObj.title,
          body: documentObj.body,
        })

      setValue(documentObj.body);
    }
  }, []);

  const saveDocs = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    if (docId) {
      if (
        currentAdmin?.user.type == 'Employee' &&
        currentAdmin?.user.employeeType !== "Admin"
      ) {
        
        const newData = { body: data, status: 2 };
        const result = await updateEmployeeDocumentService(newData, docId);
        if (result.status === 200) {
          toast.success("Document successfully updated");
        }
      } else {
        const result = await updateDocumentService({title: values.title, body: value}, docId);
        if (result.status === 200) {
          toast.success("Document successfully updated");
          let index = allDocuments.findIndex((doc) => doc.id === docId);
          allDocuments[index] = result.data.data;
          toggle();
        }
      }
    } else {
      const result = await createDocumentService({title: values.title, body: value});
      console.log(result.data.data);
      allDocuments.push(result.data.data);
      toggle();
      if (result.status === 200 || result.status === 201) {
        toast.success("Document successfully created");
      }
    }

    setIsSaving(false);
  };

  return (
    <>
      <Form className="form-bookmark needs-validation" onSubmit={saveDocs}>
        <div className="form-row row mb-3">
          <div className="row mb-3">
            <div className="form-group col-12">
              <label className="col-form-label">Title</label>
              <FormInput
                type="text"
                id="title"
                onChange={(e) => {
                  setValues({...values, title:  e.target.value});
                }}
                name="title"
                placeholder="Please enter document title here."
                value={values["title"]}
                required
              />
            </div>
            <div className="form-group col-12">
              <label className="col-form-label">Document</label>
              <CustomEditor
                name="description"
                onChange={(data) => {
                  setValue(data);
                }}
                value={value}
                editorLoaded={editorLoaded}
                readonly={
                  currentAdmin?.user.type == 'Employee' &&
                  currentAdmin?.user.employeeType !== "Admin" &&
                  status != 1
                    ? true
                    : false
                }
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>

            { 
            ((currentAdmin?.user.type === 'Admin' && currentAdmin?.user.role.includes('Admin')) || 
            currentAdmin?.user.type === 'Employee') && 
            <Button
              color="primary"
              className="mr-1"
              disabled={isSaving ? true : false}
            >
              {isSaving ? (
                <Spinner style={{ height: "20px", width: "20px" }} />
              ) : (
                "Save"
              )}
            </Button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default DocumentTemplateForm;
