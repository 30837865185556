import axios from "axios";
import { API_BASE_URL } from "../constant/constant";
import { removeUndefinedNull } from "../utils/helper.utils";

export const getAllAdmin = async (page = 1, q = '', sort = {} ) => {
    const allAdmin = await axios.get(API_BASE_URL + '/admins',
    {
        params: {
          q: q,
          page: page,
          sort: sort
        }
    });

    return allAdmin.data;
}

export const createAdminService = async (data) => {
    const admin = await axios.post(API_BASE_URL + '/admins', data);

    return admin;
}

export const updateAdminService = async (data, id) => {
    const { confirmPassword, role, createdAt, updatedAt, ...rest } = removeUndefinedNull(data);
    const admin = await axios.post(API_BASE_URL + `/admins/${id}`, {
        ...rest,
        _method: 'patch'  
    });

    return admin;
}


export const deleteAdminService = async (id) => {
    const admin = await axios.post(API_BASE_URL + `/admins/${id}`,{
        _method: 'delete'  
    });


    return admin;
}

