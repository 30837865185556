import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Spinner,
    Button,
  } from "reactstrap";
  import { useEffect, useState } from "react";
import { createDocumentFromTemplateService, getAllDocuments } from "../../../../services/documents.services";
import Breadcrumbs from "../../../../layout/breadcrumb";
import CustomModal from "../../../custom-modal/custom-modal.component";
import ViewDocument from "../../view-document/view-document.component";
import CustomBackButton from "../../../custom-back-button/custom-back-button.component";
import { selectCurrentAdmin } from "../../../../store/admin/admin.selector";
import { selectEmployees } from "../../../../store/employees/employee.selector";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


  const AdminDocumentTemplates = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [allDocuments, setAllDocuments] = useState([]);
    const [data, setData] = useState({});
    const [id, setId] = useState(null);
    const[isSaving, setIsSaving] = useState([]);
  
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const currentAdmin = useSelector(selectCurrentAdmin);

    const allEmployee = useSelector(selectEmployees);
  
    useEffect(() => {
      setIsLoading(true);
      const getDocs = async () =>
        await getAllDocuments()
          .then((result) => {
            if (result && result.data.length > 0) {
              setAllDocuments(result.data);
            }
  
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
  
      getDocs();
    }, []);
  
    
    const createDocfromTemplate = async (e, id) => {
      e.stopPropagation();
      setIsSaving([id]);
        const result = await createDocumentFromTemplateService(id);
        console.log(result.data.data);
        if (result.status === 200 || result.status === 201) {
          toast.success("Document successfully created");
        } else {
          toast.success("Document can't be created at this time, please try again later!");
        }
  
        setIsSaving([]);
    };

    return (
      <>
        <Breadcrumbs parent="Dashboard" title="Admin Document Templates" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card className="main-content-card">
                <CardHeader className="px-0 bg-transparent d-flex justify-content-between align-items-center">
                  <h5 className="d-flex gap-1 m-0 p-0">
                    <CustomBackButton /> Admin Document Templates
                  </h5>
                </CardHeader>
                <CardBody className="main-content-card-body" id="scrollableDiv">
                  <Row>
                    <table className="table align-baseline">
                      <thead>
                        <tr className="align-middle">
                          <th>#</th>
                          <th>Title</th>
                          <th>Body</th>
                          <th>Created By</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          { currentAdmin?.user.type === "Employee" && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {
                        allDocuments.filter(document => {
                          return document.company_id === null
                        }).map((doc, index) => (
                          <tr
                            key={doc.id}
                            className="main-list-hover align-middle"
                            onClick={() => {
                              setId(doc.id);
                              setData(doc);
                              toggle();
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{doc.title}</td>
                            <td>
                            <div dangerouslySetInnerHTML={{ __html: doc.body.substr(0, 100) }}></div>
                            </td>
                            <td>{doc.createdBy?.email ? doc.createdBy?.email : currentAdmin.user?.email}</td>
                            <td>{doc.createdAt}</td>
                            <td>{doc.updatedAt}</td>
                            { currentAdmin?.user.type === "Employee" && <td>
                              <Button size="sm" color="primary" onClick={(e) => createDocfromTemplate(e, doc.id) } disabled={ isSaving.indexOf(doc.id) !== -1 }>
                                { isSaving.indexOf(doc.id) !== -1 ? <Spinner /> : "Create Document"}
                              </Button>
                            </td> }
                          </tr>
                        ))}
  
                        {isLoading && (
                          <tr>
                            <td colSpan={6}>
                              <div className="text-center">
                                <Spinner />
                              </div>
                            </td>
                          </tr>
                        )}
  
                        {!isLoading && allDocuments.length === 0 && (
                          <tr>
                            <td colSpan={6}>
                              <div className="text-center">
                                <p>Document templates list is empty.</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
  
          <CustomModal
            toggle={toggle}
            modal={modal}
            modalTitle={"View document"}
            size="xl"
          >
            <ViewDocument
              toggle={toggle}
              allDocuments={allDocuments}
              id={id}
              data={data}
              templateType="Admin"
            />
          </CustomModal>
        </Container>
      </>
    );
  };
  
  export default AdminDocumentTemplates;
  