import { useState } from "react";
import "./form-input.styles.scss";
import { FileInput } from "../file-input.component/file-input.component";

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, type, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <>
      { (label && type !== "file") ? <label>{label}</label> : ''}
      {
        type === 'textarea' ? 
        (
            <textarea className="form-control" 
            {...inputProps}
              type={type}
            onChange={onChange}
            onBlur={handleFocus}
            focused={focused.toString()}></textarea>
        ) :  (

            type === 'file' ? 
            (
                <FileInput 
                {...inputProps}
                type={type}
                onChange={onChange} />
            ) : 
            (
                <input
                className="form-control"
                {...inputProps}
                type={type}
                onChange={onChange}
                onBlur={handleFocus}
                onFocus={() =>
                    inputProps.name === "confirmPassword" && setFocused(true)
                }
                focused={focused.toString()}
                />
            )
        )
      }
      <span className="form-error-span">{errorMessage}</span>
    </>
  );
};

export default FormInput;