import axios from "axios";
import { API_BASE_URL } from "../constant/constant";
import { removeUndefinedNull } from "../utils/helper.utils";

export const getAllDocuments = async (page = 1, query = '') => {
    const allDocuments = await axios.get(API_BASE_URL + '/templates', {
        params: {
          page: page,
          query: query
        }
    });

    return allDocuments.data;
}

export const createDocumentService = async (data) => {
    const document = await axios.post(API_BASE_URL + '/templates', data);

    return document;
}

export const updateDocumentService = async (data, id) => {
    const { createdAt, updatedAt, ...rest } = removeUndefinedNull(data);
    const document = await axios.post(API_BASE_URL + `/templates/${id}`, {
        ...rest,
        _method: 'patch'  
    });

    return document;
}


export const deleteDocumentService = async (id) => {
    const document = await axios.post(API_BASE_URL + `/templates/${id}`,{
        _method: 'delete'  
    });

    return document;
}

export const createDocumentFromTemplateService = async (id) => {
    const document = await axios.post(API_BASE_URL + `/create-document-from-template/${id}`);

    return document;
}