import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
// import App from './App';
import App from './App'
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, useNavigate, Navigate} from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

import axios from 'axios';
import { API_BASE_URL } from './constant/constant';
import { setCurrentAdmin } from './store/admin/admin.action';

axios.interceptors.request.use(request => {
  // add auth header with jwt if account is logged in and request is to the api url
  const token = localStorage.getItem('dms-token');
  const isLoggedIn = token;
  const isApiUrl = request.url.startsWith(API_BASE_URL);

  if (isLoggedIn && isApiUrl) {
      request.headers['Authorization'] = `Bearer ${token}`;
  }

  return request;
});


// For POST requests
axios.interceptors.response.use(
   (res) => {
     // Add configurations here
     return res;
  },
   (err) => {
     if (err.status == 401 || err.status == 403 || err.response.status == 401 || err.response.status == 403) {
      store.dispatch(setCurrentAdmin({}));
      localStorage.clear();
      return <Navigate replace to="/authentication/login" />;

     } else {
        return Promise.reject(err);
     }
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate persistor={persistor}> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
      {/* </PersistGate> */}
    </Provider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();