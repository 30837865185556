import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import { Col } from 'reactstrap';
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);


const AdminDashboard = ({dashboardObj}) => {
  
    const pieData = {
        labels: ['Admin', 'Editor', 'Viewer'],
        datasets: [
          {
            label: 'Total',
            data: [
              dashboardObj?.totalAdmins?.find(({role}) => role == 'Admin' )?.total, 
              dashboardObj?.totalAdmins?.find(({role}) => role == 'Editor' )?.total,
              dashboardObj?.totalAdmins?.find(({role}) => role == 'Viewer' )?.total
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',  
            ],
            borderWidth: 1,
          },
        ],
    };
  
    const data = {
        labels: ['Total Documents Templates', 'Admin Document Templates'],
        datasets: [
          {
            label: 'Total',
            data: [
              dashboardObj?.totalDocument, 
              dashboardObj?.totalAdminDocument, 
            ],
            backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
              'rgba(153, 102, 255, 0.2)',
            ],
            borderColor: [
            'rgba(255, 159, 64, 1)',
              'rgba(153, 102, 255, 1)',
            ],
            borderWidth: 1,
          },
        ],
    };
    return (
        <>
            <Col sm="6" className="mb-4" >
                <Doughnut data={data} />
            </Col>
            <Col sm="6" className="mb-4" >
                <Pie data={pieData} />
            </Col>
        </>
    )
}

export default AdminDashboard;