import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { useEffect, useState } from "react";
import CustomBackButton from "../../custom-back-button/custom-back-button.component";
import photoPlaceholder from "../../../assets/images/lightgallry/user-profile.jpg";
import Breadcrumbs from "../../../layout/breadcrumb";
import {
  createEmployeeDocumentService,
  getAllEmployeeDocuments,
  updateEmployeeDocumentService,
} from "../../../services/employee-documents.services";
import CustomModal from "../../custom-modal/custom-modal.component";
import ViewDocument from "../view-document/view-document.component";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomSearchBar from "../../custom-search-bar/custom-search-bar.component";
import { useCallback } from "react";
import SortIcon from "../../custom-sort-icon/custom-sort-icon.component";
import { Check, PenTool, X } from "react-feather";
import ChangeDocumentEmployee from "../change-document-employee/change-document-employee.component";
import { selectEmployees } from "../../../store/employees/employee.selector";
import { toast } from "react-toastify";
import EmployeesAvatar from "./document-assignee-dropdown";
import DocumentAssigneeDropdown from "./document-assignee-dropdown";
import ShareDocumentEmployee from "../share-document-employee/share-document-employee.component";
import { fetchEmployeesAsync } from "../../../store/employees/employee.action";

const EmployeeDocuments = ({ documentStatus = "" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allEmployeeDocuments, setAllEmployeeDocuments] = useState([]);
  const [data, setData] = useState({});
  const [id, setId] = useState(null);
  const currentAdmin = useSelector(selectCurrentAdmin);

  const allEmployee = useSelector(selectEmployees);

  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const toggle = () => setModal(!modal);
  const [query, setQuery] = useState("");

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState();

  const [selectedDocToShare, setSelectedDocToShare] = useState(null);
  const [selectedEmployeeToShare, setSelectedEmployeeToShare] = useState([]);

  const [isSharing, setIsSharing] = useState(false);

  const dispatch = useDispatch();

  const handleSort = (column) => {
    if (sortColumn === column) {
      // Toggle sort direction if same column clicked again
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      getDocs(1, query, documentStatus, {
        [column]: sortDirection ? sortDirection : "asc",
      });
    } else {
      // Set new sort column and direction
      setSortColumn(column);
      setSortDirection("asc");
      getDocs(1, query, documentStatus, {
        [column]: sortDirection ? sortDirection : "asc",
      });
    }
  };

  const [documentDetails, setDocumentDetails] = useState(null);

  useEffect(() => {
    if (currentAdmin?.user.type != 'Admin') dispatch(fetchEmployeesAsync(1,'', {}, "", "/all-company-employees"));
  }, []);

  const shareDocument = async () => {
    if (selectedEmployeeToShare && selectedEmployeeToShare.length == 0) return;
    setIsSharing(true);
    const doc = await createEmployeeDocumentService({ document: selectedDocToShare, employees: selectedEmployeeToShare.map( (emp)=> emp.value )});
    setSelectedDocToShare(null);
    setIsSharing(false);
    getDocs()
    if (doc.data.success == true) {
      toast.success('Document successfully shared with employees');
    } else {
      toast.error("Failed to share document with employees, please try again later.");
    }
  }

  const getDocs = useCallback(
    async (
      page = 1,
      q = "",
      docStatus = documentStatus ? documentStatus : 2,
      sort = {}
    ) => {
      const result = await getAllEmployeeDocuments(page, q, docStatus, sort);
      setDocumentDetails(result);

      if (result && result.data.length > 0) {
        let data = result.data;

        if (page != 1) {
          setAllEmployeeDocuments((prevDocs) => [...prevDocs, ...data]);
        } else {
          setAllEmployeeDocuments([...data]);
        }
      }
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    setIsLoading(true);
    if (
      currentAdmin?.user &&
      currentAdmin?.user.type === "Employee" &&
      currentAdmin?.user?.employeeType !== "Admin" &&
      documentStatus !== ""
    ) {
      getDocs(1, "", documentStatus).catch((error) => {
        setIsLoading(false);
      });
    } else {
      getDocs().catch((error) => {
        setIsLoading(false);
      });
    }
  }, []);

  const loadMoreEmploeeDocs = () => {
    getDocs(documentDetails?.meta?.current_page + 1);
  };

  const searchEmployee = () => {
    getDocs(1, query);
  };


  return (
    <>
      <Breadcrumbs parent="Dashboard" title="Employee Documents" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
                <h5 className="d-flex gap-1 m-0 p-0">
                  <CustomBackButton /> List Of Employee Documents
                </h5>
              </CardHeader>
              <CardBody
                style={{ height: "80vh", overflowY: "auto" }}
                id="scrollableEmployeeDocsDiv"
              >
                <CustomSearchBar
                  placeholder="Search here..."
                  setQuery={setQuery}
                  keyUpFunc={searchEmployee}
                />
                <Row>
                  {documentDetails?.meta?.total > 0 && (
                    <InfiniteScroll
                      dataLength={documentDetails?.meta?.total}
                      next={loadMoreEmploeeDocs}
                      hasMore={
                        documentDetails?.meta?.current_page <
                        documentDetails?.meta?.last_page
                      }
                      loader={
                        <div className="primary-color text-center">
                          loading...
                        </div>
                      }
                      scrollableTarget="scrollableEmployeeDocsDiv"
                      endMessage={
                        <p className="text-center primary-color">
                          <b> You have seen it all</b>
                        </p>
                      }
                    >
                      <table className="table align-baseline table-responsive">
                        <thead>
                          <tr className="align-middle">
                            <th>#</th>

                            {currentAdmin?.user?.employeeType !== "Admin" ? (
                              <>
                                <th onClick={() => handleSort("title")}>
                                  Title
                                  {sortColumn === "title" ? (
                                    <SortIcon direction={sortDirection} />
                                  ) : (
                                    "️↕️"
                                  )}
                                </th>
                                <th onClick={() => handleSort("status")}>
                                  Status
                                  {sortColumn === "status" ? (
                                    <SortIcon direction={sortDirection} />
                                  ) : (
                                    "️↕️"
                                  )}
                                </th>
                                <th onClick={() => handleSort("created_at")}>
                                  Creation Date
                                  {sortColumn === "created_at" ? (
                                    <SortIcon direction={sortDirection} />
                                  ) : (
                                    "️↕️"
                                  )}
                                </th>
                                <th>Created By</th>
                              </>
                            ) : (
                              <>
                                <th onClick={() => handleSort("title")}>
                                  Title
                                  {sortColumn === "title" ? (
                                    <SortIcon direction={sortDirection} />
                                  ) : (
                                    "️↕️"
                                  )}
                                </th>
                                <th onClick={() => handleSort("status")}>
                                  Status
                                  {sortColumn === "status" ? (
                                    <SortIcon direction={sortDirection} />
                                  ) : (
                                    "️↕️"
                                  )}
                                </th>
                                <th onClick={() => handleSort("created_at")}>
                                  Creation Date
                                  {sortColumn === "created_at" ? (
                                    <SortIcon direction={sortDirection} />
                                  ) : (
                                    "️↕️"
                                  )}
                                </th>
                                <th>
                                  Submitted By
                                </th>
                                <th>Assignees</th>
                                <th>Updated At</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {allEmployeeDocuments.map((doc, index) => (
                            <tr
                              key={doc.id}
                              className="main-list-hover align-middle"
                              onClick={() => {
                                setId(doc.id);
                                setData(doc);
                                toggle();
                                setModalTitle(
                                  doc.status == 3
                                    ? "Approved Document"
                                    : doc.status == 1
                                    ? "Open Document"
                                    : "Pending Review"
                                );
                              }}
                            >
                              <td>{index + 1}</td>

                              {currentAdmin?.user?.employeeType !== "Admin" ? (
                                <>
                                  <td>{doc.title}</td>
                                  <td>
                                    <div className="d-flex gap-1">
                                      {doc.status == 1
                                        ? "Open"
                                        : doc.status == 2
                                        ? "Pending"
                                        : doc.status == 3
                                        ? "Approved"
                                        : "Closed"}
                                    </div>
                                  </td>
                                  <td>{doc.createdAt}</td>
                                  <td>{doc.createdBy?.email}</td>
                                </>
                              ) : (
                                <>
                                  <td>{doc.title}</td>
                                  <td>
                                    <div className="d-flex gap-1">
                                      {doc.status == 1
                                        ? "Open"
                                        : doc.status == 2
                                        ? "Pending"
                                        : doc.status == 3
                                        ? "Approved"
                                        : "Closed"}
                                    </div>
                                  </td>
                                  <td>{doc.createdAt}</td>
                                  <td>{doc.updatedBy?.email}</td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center">
                                      {/* {doc.employee.name} */}
                                      <div
                                        className="employee-avatars relative"
                                        style={{ position: "relative" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        {/* Employee Dropdown */}
                                        <DocumentAssigneeDropdown
                                          documentAssignees={doc}
                                          getDocs={getDocs}
                                        />
                                      </div>

                                      <div onClick={(e) => e.stopPropagation()}>
                                        { selectedDocToShare !== doc.id && <button className="btn btn-sm btn-primary d-flex align-items-baseline gap-1" onClick={() => setSelectedDocToShare(doc.id) }>Share</button>}
                                        {( selectedDocToShare && selectedDocToShare == doc.id ) && <div className="d-flex">
                                          <ShareDocumentEmployee allEmployee={allEmployee} setSelectedEmployeeToShare={setSelectedEmployeeToShare} onClick={(e) => e.stopPropagation()} className="w-75" />
                                          <button className="btn btn-default text-success" onClick={shareDocument} disabled={isSharing}>{isSharing ? <Spinner /> : <Check />} </button>
                                          <button className="btn btn-default text-danger" onClick={() => { setSelectedDocToShare(null); setIsSharing(false); } }><X /> </button>
                                        </div>}
                                      </div> 
                                    </div>
                                  </td>
                                  <td>{doc.updatedAt}</td>
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  )}
                  {isLoading && (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  )}
                  {((!isLoading && allEmployeeDocuments.length === 0) ||
                    documentDetails?.total == 0) && (
                    <div className="text-center">
                      <p>Employee Document list is empty.</p>
                    </div>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <CustomModal
          toggle={toggle}
          modal={modal}
          modalTitle={modalTitle}
          size="xl"
        >
          <ViewDocument
            toggle={toggle}
            allEmployeeDocuments={allEmployeeDocuments}
            id={id}
            data={data}
          />
        </CustomModal>
      </Container>
    </>
  );
};

export default EmployeeDocuments;
