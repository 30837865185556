import axios from "axios";
import { API_BASE_URL } from "../constant/constant";

export const fileUpload = async (selectedFile, setProgress) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    const file = await axios.post(API_BASE_URL + '/file-upload', formData, {
        onUploadProgress: data => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total))
        }
    });

    return file;
}