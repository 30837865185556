import Admin from '../components/admin/admin.component';
import Companies from '../components/companies/companies.component';
import AllDocumentTemplates from '../components/documents/document-templates/admin-templates/all-template.component';
import DocumentTemplates from '../components/documents/document-templates/document-templates.component';
import Employee from '../components/employee/employee.component';

export const adminRoutes = [
    { path:`admins`, Component:Admin},
    { path:`companies`, Component:Companies},
    { path:`employees`, Component:Employee},
    { path:`doc-templates`, Component:DocumentTemplates},
    { path:`all-documents`, Component:AllDocumentTemplates},
    { path:`companies/:companyId/:companyName/employee`, Component:Employee},
]