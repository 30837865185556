import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Spinner,
  } from "reactstrap";
  import { useCallback, useEffect, useState } from "react";
import { getAllDocuments } from "../../../../services/documents.services";
import Breadcrumbs from "../../../../layout/breadcrumb";
import CustomModal from "../../../custom-modal/custom-modal.component";
import ViewDocument from "../../view-document/view-document.component";
import CustomBackButton from "../../../custom-back-button/custom-back-button.component";
import { selectCurrentAdmin } from "../../../../store/admin/admin.selector";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import DocumentTemplateForm from "../document-template-form/document-template-form.component";


const AllDocumentTemplates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allDocuments, setAllDocuments] = useState(null);
  const [data, setData] = useState({});
  const [id, setId] = useState(null);


  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);


  const currentAdmin = useSelector(selectCurrentAdmin);

  const getDocs = useCallback(async (page = 1) => {
    await getAllDocuments(page, 'all')
      .then((result) => {
        if (result && result.data.length > 0) {
          setAllDocuments((prevDocs) => {
            if (page !== 1) {
              return { ...result, data: [...prevDocs.data, ...result.data] };
            } else {
              return result;
            }
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      })
  }, [])


  useEffect(() => {
    setIsLoading(true);
    getDocs();
  }, []);

  const loadMoreDocuments = () => {
    console.log("allDocuments?.meta?.current_page + 1", allDocuments?.meta?.current_page + 1)
    getDocs(allDocuments?.meta?.current_page + 1);
  }

  return (
    <>
      <Breadcrumbs parent="Dashboard" title="All Document Templates" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="px-0 bg-transparent d-flex justify-content-between align-items-center">
                <h5 className="d-flex gap-1 m-0 p-0">
                  <CustomBackButton /> All Document Templates
                </h5>
              </CardHeader>
              <CardBody className="main-content-card-body" id="scrollableDiv">
                <Row>
                  { allDocuments?.data?.length > 0 && <InfiniteScroll
                    dataLength={allDocuments?.data?.length}
                    next={loadMoreDocuments}
                    hasMore={allDocuments?.meta?.current_page < allDocuments?.meta?.last_page}
                    loader={<div className="primary-color text-center"> loading... </div>}
                    scrollableTarget="scrollableEmployeeDiv"
                    endMessage={
                      <p className="text-center primary-color">
                        {!isLoading && <b> You have seen it all</b>}
                      </p>
                    }
                  >
                    <table className="table align-baseline">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Title</th>
                          <th>Body</th>
                          <th>Created By</th>
                          <th>Template Owner</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                        allDocuments?.data?.map((doc, index) => (
                          <tr
                            key={doc.id}
                            className="main-list-hover"
                            onClick={() => {
                              setId(doc.id);
                              setData(doc);
                              toggle();
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{doc.title}</td>
                            <td>
                              <div dangerouslySetInnerHTML={{ __html: doc.body.substr(0, 100) }}></div>
                            </td>
                            <td>{doc.createdBy?.email ? doc.createdBy?.email : currentAdmin.user?.email}</td>
                            <td>{doc.company ? doc.company.name : "Admin" }</td>
                            <td>{doc.createdAt}</td>
                            <td>{doc.updatedAt}</td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </InfiniteScroll>}

                  {isLoading && (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  )}

                  {!isLoading && allDocuments?.data?.length === 0 && (
                      <div className="text-center">
                        <p>Document templates list is empty.</p>
                      </div>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <CustomModal
          toggle={toggle}
          modal={modal}
          modalTitle={"Add | Edit Document"}
          size="xl"
        >
          <DocumentTemplateForm
            toggle={toggle}
            allDocuments={(allDocuments && allDocuments.data) ? allDocuments.data: [] }
            id={id}
            documentObj={data}
          />
        </CustomModal>
      </Container>
    </>
  );
};

export default AllDocumentTemplates;
  