import { COMPANY_ACTION_TYPES } from "./company.types";

const COMPANY_INITIAL_STATE = {
  companies: {
    data: [],
    links: {},
    meta: {},
  },
  isLoading: false
};

export const companyReducer = (state = COMPANY_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case COMPANY_ACTION_TYPES.FETCH_COMPANIES_START:
      return { ...state, isLoading: true}
    case COMPANY_ACTION_TYPES.FETCH_COMPANIES_SUCCESS:
        return { ...state, companies: payload, isLoading: false }
    case COMPANY_ACTION_TYPES.FETCH_COMPANIES_FAILED:
        return { ...state, error: payload, isLoading: false }
    default:
        return state;
  }
};