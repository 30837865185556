import { useSelector } from "react-redux";
import {
  updateEmployeeDocumentService,
  createEmployeeDocumentService,
} from "../../../services/employee-documents.services";
import SweetAlert from "sweetalert2";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import FormInput from "../../form-input/form-input.component";
import { useEffect, useState } from "react";
import CustomEditor from "../custom-editor/custom-editor.component";
import { Button, Spinner } from "reactstrap";
import { toast } from "react-toastify";

const ViewDocument = ({ toggle, id, data, allEmployeeDocuments, templateType = 'Employee' }) => {
  const currentAdmin = useSelector(selectCurrentAdmin);
  const [response, setResponse] = useState();


  const [editorLoaded, setEditorLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const [value, setValue] = useState("");

  const [values, setValues] = useState({
    title: "",
    body: "",
  });

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setValues({
        title: data.title,
        body: data.body,
      });

      setValue(data.body);
    }
  }, []);

  const onChange = (e) => {
    setResponse(e.target.value);
  };

  const handleApproveDocument = (status = 3) => {
    let approvedOrRejected = status == 3 ? "approved" : "rejected";
    SweetAlert.fire({
      title: "Are you sure?",
      text:
        status == 3
          ? "Once approved, employee can't change the document."
          : "Do you want to reject ttttt?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        updateEmployeeDocumentService(
          { status: status, response: response },
          id
        ).then(
          () => {
            SweetAlert.fire(
              approvedOrRejected,
              `successfully ${approvedOrRejected}.`,
              "success"
            );
            const index = allEmployeeDocuments.findIndex(
              (doc) => doc.id === id
            );

            allEmployeeDocuments[index].status = status;
            toggle();
          },
          (err) => {
            SweetAlert.fire(
              `document is not ${approvedOrRejected}`,
              `Your document has not been ${approvedOrRejected}.`,
              "error"
            );
            toggle();
          }
        );
      } else {
        SweetAlert.fire("Your document is save!");
      }
    });
  };

  const saveDocument = async (e, status) => {
    e.preventDefault();

    if (status === 1) {
      setIsSaving(true);
      updateEmployeeDocumentService(
        { title: values.title, body: value, status: 1 },
        id
      ).then(
        (res) => {
          toast.success("Document successfully updated");

          let index = allEmployeeDocuments.findIndex((doc) => doc.id === id);
          allEmployeeDocuments[index]["status"] = status;
          allEmployeeDocuments[index]["title"] = values.title;
          allEmployeeDocuments[index]["body"] = value;
          toggle();
          setIsSaving(false);
        },
        (err) => {
          toast.success("document does not updated, please try again later.");
          toggle();
          setIsSaving(false);
        }
      );
    } else {
      setIsSubmitting(true);
      SweetAlert.fire({
        title: "Are you sure?",
        text: "Once you have confirmed and saved your document  you can't modify it again.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          updateEmployeeDocumentService(
            { title: values.title, body: value, status: 2 },
            id
          ).then(
            () => {
              SweetAlert.fire("Updated", `successfully updated.`, "success");

              toast.success("Document successfully updated");

              let index = allEmployeeDocuments.findIndex(
                (doc) => doc.id === id
              );
              allEmployeeDocuments.splice(index, 1);
              toggle();
              setIsSubmitting(false);
            },
            (err) => {
              SweetAlert.fire(
                `document does not updated, please try again later.`,
                `Your document has not been updated.`,
                "error"
              );
              toggle();
              setIsSubmitting(false);
            }
          );
        } else {
          SweetAlert.fire("Your document is save!");
        }
      });
    }
    setIsSubmitting(false);
    setIsSaving(false);
  };

  const duplicatDocument = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    const result = await createEmployeeDocumentService({
      title: values.title + " Copy",
      body: values.body,
      document_id: id,
    });
    toggle();
    if (result.status === 200 || result.status === 201) {
      toast.success("Document successfully created");
    }

    setIsSaving(false);
  };

  return (
    <div className="">
      {data.status == 3 ? (
        <h6 className="mb-2 mt-2">{data.title}</h6>
      ) : (
        <div className="form-group mb-4">
          <label className="col-form-label">
            <strong>Title</strong>
          </label>
          <FormInput
            type="text"
            id="title"
            onChange={(e) => {
              setValues({ ...values, title: e.target.value });
            }}
            name="title"
            placeholder="document title"
            value={values["title"]}
            required
          />
        </div>
      )}
      <div>
        {!(
          currentAdmin?.user &&
          currentAdmin?.user.type === 'Employee' &&
          currentAdmin?.user?.employeeType !== "Admin"
        ) ? (
          <>
            <div
              style={{
                maxHeight: "calc(100vh - 200px)",
                overflowY: "scroll",
              }} 
            >
              <CustomEditor
                  name="description"
                  onChange={(data) => {
                    setValue(data);
                  }}
                  value={data && data.body ? data.body : ""}
                  editorLoaded={editorLoaded}
                  readonly={
                    currentAdmin?.user.type == 'Employee' &&
                    currentAdmin?.user.employeeType !== "Admin" &&
                    data.status != 1
                      ? true
                      : false
                  }
                />

            </div>
            
            {data.status == 2 && (
              <>
                <div className="form-group mb-3">
                  <label htmlFor="response">
                    <strong>Would you like to write a comment ?</strong>
                  </label>
                  <FormInput
                    type="text"
                    id="response"
                    onChange={onChange}
                    name="response"
                    placeholder="for example. you should change this part."
                    value={response}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-secondary w-25"
                    onClick={() => handleApproveDocument(1)}
                  >
                    Reject
                  </button>
                  <button
                    className="btn btn-primary w-25"
                    onClick={() => handleApproveDocument(3)}
                  >
                    Approve
                  </button>
                </div>
              </>
            )}

            <div className="d-flex justify-content-between mt-5">
              <Button color="light" onClick={toggle}>
                Cancel
              </Button>

              {((data.status == 1 && templateType === 'Employee' ) || currentAdmin?.user?.type.includes("Admin") ) && (
                <div className="d-flex gap-1">
                  <Button
                    color="primary"
                    disabled={isSaving ? true : false}
                    onClick={(e) => {
                      saveDocument(e, 1);
                      setIsSaving(true);
                    }}
                  >
                    {isSaving ? (
                      <Spinner style={{ height: "20px", width: "20px" }} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="form-group col-12">
              <label className="col-form-label">
                <strong>Document</strong>
              </label>
              {data.status == 1 ? (
                <CustomEditor
                  name="description"
                  onChange={(data) => {
                    setValue(data);
                  }}
                  value={data && data.body ? data.body : ""}
                  editorLoaded={editorLoaded}
                  readonly={
                    currentAdmin?.user.type == 'Employee' &&
                    currentAdmin?.user.employeeType !== "Admin" &&
                    data.status != 1
                      ? true
                      : false
                  }
                />
              ) : (
                <div 
                style={{
                  maxHeight: "calc(100vh - 200px)",
                  overflowY: "scroll",
                }}
                dangerouslySetInnerHTML={{ __html: data.body }}></div>
              )}

              {data.response && (
                <>
                  <label className="col-form-label text-warning">Comment</label>
                  <p>{data.response}</p>
                </>
              )}
            </div>

            <div className="d-flex justify-content-between mt-5">
              <Button color="light" onClick={toggle}>
                Cancel
              </Button>

              {data.status == 1 && (
                <div className="d-flex gap-1">
                  <Button
                    color="primary"
                    disabled={isSaving ? true : false}
                    onClick={(e) => {
                      saveDocument(e, 1);
                      setIsSaving(true);
                    }}
                  >
                    {isSaving ? (
                      <Spinner style={{ height: "20px", width: "20px" }} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                  <Button
                    color="primary"
                    className="mr-1"
                    disabled={isSubmitting ? true : false}
                    onClick={(e) => {
                      saveDocument(e, 2);
                      setIsSubmitting(true);
                    }}
                  >
                    {isSubmitting ? (
                      <Spinner style={{ height: "20px", width: "20px" }} />
                    ) : (
                      "Submit For Review"
                    )}
                  </Button>
                </div>
              )}
              {data.status == 3 && (
                <Button color="primary" onClick={duplicatDocument}>
                  {isSaving ? (
                    <Spinner style={{ height: "20px", width: "20px" }} />
                  ) : (
                    "Duplicate Document"
                  )}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewDocument;
