
import Employee from '../components/employee/employee.component';
import AdminDocumentTemplates from '../components/documents/document-templates/admin-templates/admin-template.component';
import DocumentTemplates from '../components/documents/document-templates/document-templates.component';
import EmployeeDocuments from '../components/documents/employee-documents/employee-documents.component';

export const companyRoutes = [
    { path:`employees`, Component:Employee},
    { path:`doc-admin-templates`, Component:AdminDocumentTemplates},
    { path:`doc-templates`, Component:DocumentTemplates},
    { path:`emp-docs`, Component:EmployeeDocuments},
]